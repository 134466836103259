export const URL_PASTE = 'https://vip.hdlatino.us/pastes/?v=';
export const motivoOptions = {
    '': 'Selecciona un motivo',
    falta_caratula: 'Falta la caratula',
    enlaces_publicos_caidos: 'Enlaces públicos caídos',
    enlaces_vip_caidos: 'Enlaces VIP caídos',
    enlaces_publicos_y_vip_caidos: 'Enlaces públicos y VIP caídos',
    acortadores_no_llevan_sitio: 'Acortadores no llevan a ningún sitio',
    serie_o_coleccion_incompleta: 'Serie o colección incompleta',
    mas_de_3_acortadores: 'El aporte tiene más de 3 acortadores',
    spam: 'El archivo tiene SPAM (Audio / Subtitulos)',
    audio_o_video_incorrecto: 'Audio y/o video incorrecto',
    post_separated: 'Post apartado',
    otro: 'Otro',
    solicitud_de_contenido: 'Solicitud de contenido'
};

export const HORA_FINALIZAR = 2;

// post-reportados
export const URLS_LOCALES = {
    post_separated: '/fichar-post',
    solicitud_de_contenido: '/pedidos'
};

// ESTILOS
export const StyleForDragAndDrop = {
    width: '70%',
    height: '10%'
};

// ESTILOS BOTON MODAL
export const sxButton = {
    sx: {
        backgroundColor: '#FFC206',
        color: 'black',
        fontWeight: 'bold',
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        '&:hover': {
            backgroundColor: '#d1a10a',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained'
};

export const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 12,
    p: 1,
    overflowY: 'auto', // Agregar scroll vertical cuando sea necesario
    maxHeight: '90vh' // Limitar la altura para evitar que el modal sea demasiado grande
};
