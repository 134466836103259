import React, { useEffect, useState } from 'react';
import { useStore } from '../../store/StoreProvider';
import { getPastes } from '../../utils/apipaste';
import DataTablePastes from './DataTablePastes';
import { Container } from 'react-bootstrap';

const MultiPaste = () => {
    const { multipasteCreado } = useStore();
    const [arrayPost, setArrayPost] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleGetPastes = async () => {
        setLoading(true);
        const { data } = await getPastes();
        setArrayPost(data);
        setLoading(false);
    };

    useEffect(() => {
        handleGetPastes();
    }, [multipasteCreado]);

    return (
        <DataTablePastes
            arrayPost={arrayPost}
            loading={loading}
            multipasteCreado={multipasteCreado}
        />
    );
};

export default MultiPaste;
