import { uploadimg, uploadImgImgur, uploadImgPixhostFile } from './api';

// 9032662b1eebea80f969a4c6b629250f
export const subirPoster = async fileposter => {
    let formData = new FormData();
    formData.append('image', fileposter);

    const BBB = [
        '96a4555e7f77688807102d50a883d43f',
        '635ed4ce3ad75f557ea60f6010aea1d4'
    ];
    const random = Math.floor(Math.random() * BBB.length);

    console.log('random', random);

    const { data, status } = await uploadimg(BBB[random], formData);

    if (status !== 200) {
        return 'Error';
    }

    return data;
};

// subir poster a imgur
export const subirPosterImgur = async fileposter => {
    let formData = new FormData();
    formData.append('image', fileposter);

    const { data } = await uploadImgImgur(formData);

    return data;
};

export const subirPosterPixhostFile = async fileposter => {
    let formData = new FormData();
    formData.append('file', fileposter);

    const data = await uploadImgPixhostFile(formData);

    return data;
};

export const handleSubirPoster = async (file) => {
    try {
        // Primer intento con subirPoster
        const result = await subirPoster(file);
        if (result !== 'Error') {
            return result;
        }

        // Segundo intento con subirPosterImgur
        try {
            const imgurResult = await subirPosterImgur(file);
            if (imgurResult) {
                return imgurResult;
            }
        } catch (imgurError) {
            console.log('Error al subir a Imgur, intentando siguiente método...', imgurError);
        }

        // Tercer intento con subirPosterPixhostFile
        try {
            const pixhostResult = await subirPosterPixhostFile(file);
            if (pixhostResult) {
                return pixhostResult;
            }
        } catch (pixhostError) {
            console.log('Error al subir a Pixhost', pixhostError);
        }

        throw new Error('Todos los métodos de subida fallaron');
    } catch (error) {
        console.error('Error en handleSubirPoster:', error);
        return null;
    }
};
