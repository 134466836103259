import React, { useEffect, useState } from 'react';
import { getInfo } from 'react-mediainfo';
import DragAndDrop from '../DragArea/DragAndDrop';
import MediaInfoView from './MediaInfoView';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import {
    buildBracketStringFromTitles,
    bytesToGB,
    extracNameAudio,
    extracNameText,
    extraerParametros,
    objectToCustomString
} from '../../utils/functions';
import { Stack } from '@mui/material';

function MediaInfoComponent() {
    const { search } = useStore();
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [mediaInfo, setMediaInfo] = useState(null);
    const [sendMediainfo, setSendMediainfo] = useState(false);
    const [sendMediainfoPer, setSendMediainfoPer] = useState(false);
    const [mediainfoSelected, setMediainfoSelected] = useState(null);

    const handleFolderSelect = async () => {
        try {
            const directory = await getDirectoryHandle();
            await detectFiles(directory);
        } catch (error) {
            console.error('Error al acceder a la carpeta:', error);
        }
    };

    const getDirectoryHandle = async () => {
        return await window.showDirectoryPicker();
    };

    const detectFiles = async directoryHandle => {
        const entries = await directoryHandle.getEntries();

        for (const entry of entries) {
            if (entry.isFile) {
                console.log('Archivo encontrado:', entry.name);
            } else if (entry.isDirectory) {
                const subDirectory = await entry.getDirectory();
                await detectFiles(subDirectory);
            }
        }
    };

    const hanleGetInfo = async file => {
        const info = await getInfo(file);
        setMediaInfo(info);

        const fileSize = bytesToGB(file?.size);

        if (search?.typeSearch === '1') {
            dispatch({
                type: types.DATAAENVIAR,
                payload: { PesoArchivo: `${fileSize} GB` }
            });
        }
        dispatch({
            type: types.DATAAENVIAR,
            payload: {
                audioTitles: buildBracketStringFromTitles(info)
            }
        });

        const nameAudiosMediainfo = extracNameAudio(info);
        const nameTextMediainfo = extracNameText(info);

        const audioEditado = [
            ...nameAudiosMediainfo.map((name, index) => ({
                id: index + 99999999,
                label: name,
                value: name
            }))
            // { label: value, value: value }
        ];
        dispatch({
            type: types.DATAAENVIAR,
            payload: {
                FormatoAudioMediainfo: audioEditado,
                FormatoSubtitulosMediainfo: nameTextMediainfo
            }
        });
    };

    const handleFileChange = async event => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);

        hanleGetInfo(selectedFile);
    };

    const handleChangeSendMediainfo = e => {
        setSendMediainfo(e.target.checked);
        setSendMediainfoPer(false);
    };

    const handleChangeSendMediainfoPersonalization = e => {
        setSendMediainfoPer(e.target.checked);
        setSendMediainfo(false);
    };

    const handleSeveMediaInfo = media => {
        console.log('media', media);
        dispatch({
            type: types.DATAAENVIAR,
            payload: {
                showMediaInfo: media
            }
        });
    };

    useEffect(() => {
        const media = sendMediainfo
            ? objectToCustomString(mediaInfo.media.track)
            : sendMediainfoPer
            ? extraerParametros(mediaInfo)
            : '';
        handleSeveMediaInfo(media);
    }, [sendMediainfo, sendMediainfoPer]);

    return (
        <Stack direction={'column'} gap={1}>
            <DragAndDrop
                handleChange={handleFileChange}
                title="Arrastra y suelta un archivo"
            />
            {mediaInfo && (
                <MediaInfoView
                    mediaInfo={mediaInfo}
                    file={file}
                    sendMediainfo={sendMediainfo}
                    sendMediainfoPer={sendMediainfoPer}
                    handleChangeSendMediainfo={handleChangeSendMediainfo}
                    handleChangeSendMediainfoPer={
                        handleChangeSendMediainfoPersonalization
                    }
                />
            )}
        </Stack>
    );
}

export default MediaInfoComponent;
