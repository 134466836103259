import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPaste } from '../../utils/apipaste';
import Paste from './Paste';
import { useServerStore } from './store/ServersStroe';
import {
    createObjectIconServer,
    createObjectUrlImagesPaste,
    createObjectUrlImagesPasteVip
} from '../../utils/functions';

const urlImagesPaste = {
    1: 'https://dl.dropbox.com/s/wf9ewehivbt6wtl/GoogleDrive_FREE%20SOLO.png',
    2: 'https://dl.dropbox.com/s/5atd2ce65ztkeki/GoogleDrive_FREE%20SOLO2.png',
    3: 'https://dl.dropbox.com/s/hgtw1lcv8cx5uah/Uptobox%20FREE.png',
    4: 'https://dl.dropbox.com/s/mmu8bp709muwm1o/TeraBox%20FREE.png',
    5: 'https://dl.dropbox.com/s/9j1bedozl8jnvtl/1Fichier%20FREE.png',
    6: 'https://dl.dropbox.com/s/c2opswe8ebi7211/GoogleDrive_VIP.png',
    7: 'https://dl.dropbox.com/s/1q1evrc7d9w6f15/GoogleDrive_VIP2.png',
    8: 'https://dl.dropbox.com/s/qgquq3qrtkx6key/Sync_VIP.png'
};
const urlImagesPasteVip = {
    1: 'https://dl.dropbox.com/s/ijvpgp7h6430zh6/GoogleDrive_FREE.png',
    2: 'https://dl.dropbox.com/s/bwh8xkec2esgrpa/GoogleDrive_FREE2.png',
    3: 'https://dl.dropbox.com/s/4et8ikxc9zve218/Uptobox.png',
    4: 'https://dl.dropbox.com/s/h95bake1afgy7qs/TeraBox.png',
    5: 'https://dl.dropbox.com/s/nnwbzobyd0d5ojh/1Fichier.png',
    6: 'https://dl.dropbox.com/s/c2opswe8ebi7211/GoogleDrive_VIP.png',
    7: 'https://dl.dropbox.com/s/1q1evrc7d9w6f15/GoogleDrive_VIP2.png',
    8: 'https://dl.dropbox.com/s/qgquq3qrtkx6key/Sync_VIP.png'
};

const EditPaste = ({ pasteId = false }) => {
    const params = useParams();
    const currenParam = pasteId ? pasteId : params?.id;
    const [datapaste, setDataPaste] = useState();
    const [enlacesFormateados, setEnlacesFormateador] = useState([]);
    const [initialdata, setInitialData] = useState({});
    const [loading, setLoading] = useState(true);

    const [data, _, __, loadingServerStore] = useServerStore();
    const iconServer = createObjectIconServer(data);
    const urlImagesPaste = createObjectUrlImagesPaste(data);
    const urlImagesPasteVip = createObjectUrlImagesPasteVip(data);

    const handleGetPaste = async () => {
        setLoading(true);
        const data = await getPaste(currenParam);

        if (data?.status === 'success') {
            setDataPaste(data?.data);

            if (
                data?.data?.paste[0]?.type_paste === 'serie' ||
                data?.data?.paste[0]?.type_paste === 'pelicula'
            ) {
                const enlaces = JSON.parse(data?.data?.paste[0]?.enlaces_json);
                const initialData = {
                    ...data?.data?.paste[0],
                    _titulo: data?.data?.paste[0]?.Titulo,
                    _typePaste: data?.data?.paste[0]?.vip,
                    _enlaces: enlaces
                };
                setInitialData(initialData);
            } else if (data?.data?.paste[0]?.type_paste === 'coleccion') {
                const enlaces = JSON.parse(data?.data?.paste[0]?.enlaces_json);
                const initialData = {
                    ...data?.data?.paste[0],
                    _titulo: data?.data?.paste[0]?.Titulo,
                    _typePaste: data?.data?.paste[0]?.vip,
                    _enlaces: enlaces
                };
                setInitialData(initialData);
            } else {
                const enlacesFormateadoss = handleFormatEnlaces(
                    data?.data?.paste[0]?.Mirror1
                );
                setEnlacesFormateador(enlacesFormateadoss);
                const InitialData = handleBuildObj(
                    enlacesFormateadoss,
                    data?.data?.paste[0]
                );
                setInitialData(InitialData);
            }
        }
        setLoading(false);
    };

    const handleFormatEnlaces = text => {
        // const textSerie = text.match(
        //     /\[\/color\]\[img\](.*)\[\/img\] \[b\]\[color\=\#b50000\]\[size\=80px\](.*)\[\/size\] \[\/color\]\[\/b\]/g
        // );

        // if (textSerie.length > 0) {
        //     let eFormateadosSerie = [];
        //     for (let i = 0; i < text2.length; i++) {
        //         eFormateadosSerie.push({
        //             type: textSerie[i].match(
        //                 /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        //             )[0],
        //             numero: textSerie[i].match(/(\d+)/g)[
        //                 textSerie[i].match(/(\d+)/g).length - 1
        //             ]
        //         });
        //     }
        // }

        const text2 = text.match(
            /\[url=([^\s\]]+)\s*\](.*(?=\[\/url\]))\[\/url\]/g
        );
        let eFormateados = [];
        for (let i = 0; i < text2.length; i++) {
            eFormateados.push({
                enlace: text2[i].split(']')[0].slice(5),
                img: text2[i].split(']')[2].slice(0, -5)
            });
        }

        return eFormateados;
    };

    const handleBuildObj = (arrayEnlacesObj1, obj) => {
        const excluir = [
            'https://tawk.to/Pedidos',
            'https://t.me/viphdlatino/?s=CHRISHD',
            'https://vip.hdlatino.us/%e2%9a%9c-membresia-vip-%f0%9f%8f%86/',
            'https://hdlatinovip.mysellix.io/group/61490174095e3',
            'https://vip.hdlatino.us/tag/saw-collection',
            'https://vip.hdlatino.us/tag/john-Wick-Collection',
            'https://vip.hdlatino.us/tag/gran-turismo-coleccion',
            'https://t.me/viphdlatino',
            'https://vip.hdlatino.us/tag/john-Wick-Collection'
        ];
        // Excluimos aquellos enlaces que no son para descargas
        const arrayEnlacesObj = arrayEnlacesObj1.filter(
            e => !excluir.includes(e.enlace)
        );

        // obtener el tipo de enlaces iterando sobre las imagenes
        for (let i = 0; i < arrayEnlacesObj.length; i++) {
            for (let key in urlImagesPaste) {
                if (urlImagesPaste[key] === arrayEnlacesObj[i].img) {
                    arrayEnlacesObj[i]['typeEnlace'] = key;
                    arrayEnlacesObj[i]['id'] = nanoid();
                }
            }

            for (let key in urlImagesPasteVip) {
                if (urlImagesPasteVip[key] === arrayEnlacesObj[i].img) {
                    arrayEnlacesObj[i]['typeEnlace'] = key;
                    arrayEnlacesObj[i]['id'] = nanoid();
                }
            }
        }

        return {
            ...obj,
            _titulo: obj?.Titulo,
            _typePaste: obj?.vip,
            _enlaces: arrayEnlacesObj
        };
    };

    useEffect(() => {
        handleGetPaste();
    }, []);

    return loading ? (
        <div>Cargando...</div>
    ) : (
        <Paste initialdata={initialdata} />
    );
};

export default EditPaste;
