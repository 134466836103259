import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import images from '../../assets/images';
import Layout from '../../layout/Layout';
import LayoutPaste from '../../layout/LayoutPaste';
import CrearPortada from '../CrearPortada';
import MultiPaste from '../dataTable/MultiPaste';
import TitleIcons from '../titleIcons/TitleIcons';
import useFillTabs from './useFillTabs';
import LayoutCrop from '../ImageCropper';
import { Stack } from '@mui/material';
import SubirPortadas from '../subirPortadas';

const FillTabs = ({
    defaultActiveKey = 'crear_portada',
    rootTypeSearch = '1',
    keyTabDefaultMultipaste = 'misenlaces'
}) => {
    const { handleSetKey, fillTabs } = useFillTabs(defaultActiveKey);

    return (
        <>
            <Stack direction={'row'}>
                <div className='container'>
                    <Tabs
                        defaultActiveKey={defaultActiveKey}
                        activeKey={fillTabs}
                        onSelect={k => handleSetKey(k)}
                        id="justify-tab-example"
                        className="mb-3 d-flex flex-wrap align-items-center justify-content-center">
                        {/* Tab para crear portada */}
                        <Tab
                            eventKey="crear_portada"
                            title={
                                <TitleIcons
                                    title="Crear Portada"
                                    img={images.crear_portada_menu}
                                />
                            }>
                            <CrearPortada rootTypeSearch={rootTypeSearch} />
                        </Tab>
                        {/* Tab para crear Post */}
                        <Tab
                            eventKey="crear_post"
                            title={
                                <TitleIcons
                                    title="Crear Post"
                                    img={images.crear_post1}
                                />
                            }>
                            <Layout />
                        </Tab>

                        {/* Tab para crear Pastes */}
                        <Tab
                            eventKey="crear_paste"
                            title={
                                <TitleIcons
                                    title="Crear Paste"
                                    img={images.crear_paste}
                                />
                            }>
                            <LayoutPaste
                                keyTabDefault={keyTabDefaultMultipaste}
                            />
                        </Tab>

                        {/* Tab para ver los pastes */}
                        <Tab
                            eventKey="multipaste"
                            title={
                                <TitleIcons
                                    title="Mis Pastes"
                                    img={images.mis_pastes}
                                />
                            }>
                            <MultiPaste />
                        </Tab>

                        {/* Tab para crop image */}
                        <Tab
                            eventKey="crop_image"
                            title={
                                <TitleIcons
                                    title="Crop Image"
                                    img={images.cropIcon}
                                />
                            }>
                            {/* <CropperImg /> */}
                            {/* <DemoCrop /> */}
                            <LayoutCrop />
                            {/* <Demo /> */}
                        </Tab>
                        {/* Tab para subir imágenes */}
                        <Tab
                            eventKey="subir_portada"
                            title={
                                <TitleIcons
                                    title="Subir Capturas"
                                    img={images.cropIcon}
                                />
                            }>
                            <SubirPortadas />
                        </Tab>
                        
                    </Tabs>
                </div>
                {/* <LayoutNotes /> */}
            </Stack>
        </>
    );
};

export default FillTabs;
