import React, { useEffect, useState } from 'react';
import { Col, Dropdown, FormControl, InputGroup, Row } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';
import Button from '../../components/button/Button';
import Form from 'react-bootstrap/Form';
import styles from './search.module.css';
import { getMovies } from '../../utils/themvoies';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import { FaSearch } from 'react-icons/fa';
import {
    definirTrailer,
    handleDirectors,
    handleRuntime
} from '../../utils/functions';
import { getTraduction } from '../../utils/traductor';
import { getSearchJustWhatch } from '../../utils/justWhatch';
import Loading from '../Loading/Loading';
import { getSearchFilmaffinityOficial } from '../../utils/filmaffinityOficial';
import { useSearch } from '../movie/store';
import SearchCoincidence from '../SearchCoincidence/SearchCoincidence';
import SearchMainCoincidence from '../SearchCoincidence/SearchMainCoincidence';
import MovieCard from '../MovieCard/MovieCard';
import { useSearchCoincidence } from '../SearchCoincidence/store/store';

const Search = ({ setShow, show, isPoster = false, rootTypeSearch = '1' }) => {
    const [search, setSearch] = useSearch();
    const { TMDB, view } = useStore();
    const [datosbusqueda, setDatosBusquda] = useState({
        tmdb: '',
        movie: '',
        typeSearch: rootTypeSearch,
        idioma: 'es-MX'
    });
    const [myidioma, setMyIdioma] = useState('es-MX');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { searchCoincidence, setPostSearchCoincidence } =
        useSearchCoincidence();

    const { tmdb, movie, typeSearch, idioma } = datosbusqueda;

    const handleChange = e => {
        setDatosBusquda({
            ...datosbusqueda,
            [e.target.name]: e.target.value
        });
    };
    const handleChangeAutoComplete = value => {
        console.log('value', value);
        setDatosBusquda({
            ...datosbusqueda,
            movie: value
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);
        // let descripcionTraducida = '';
        const datam = await getMovies(datosbusqueda);

        const resultPelisJustWhatch = await getSearchJustWhatch(
            datosbusqueda?.movie
        );
        const resultPelisFilmaffinity = await getSearchFilmaffinityOficial(
            datosbusqueda?.movie
        );

        dispatch({
            type: types.PELIS_JUSTWHATCH_SEARCH,
            payload: resultPelisJustWhatch
        });
        dispatch({
            type: types.PELIS_FILMAFFINITY_SEARCH,
            payload: resultPelisFilmaffinity
        });

        if (!datam.overview && datam.title?.length > 0) {
            const datam2 = await getMovies({
                ...datosbusqueda,
                idioma: 'en-US'
            });
            const description = await getTraduction(datam2?.overview);
            description?.text && (datam.overview = description.text);
            description?.text && (datam.traducido = true);
        }

        if (datam?.results?.length > 0) {
            isPoster && setShow(!show);
        }

        if (tmdb) {
            const misDirectores = handleDirectors(datam, typeSearch === '1');
            const trailer1 = definirTrailer(datam) ?? 'Sin Trailer';
            const runtime = handleRuntime(datam);
            const year =
                datam?.release_date?.slice(0, -6) ||
                datam?.first_air_date?.slice(0, -6) ||
                'Sin año';
            const paisOrigen =
                datam?.production_countries?.[0]?.name || 'Sin pais';
            const generos =
                datam?.genres?.length > 0
                    ? datam.genres.flatMap(ge =>
                          ge.name.split(' & ').map(g => ' ' + g)
                      )
                    : null;
            const director = misDirectores.join(',') || 'Sin Director';
            const imdb = datam?.external_ids?.imdb_id || 'Sin IMDB';
            const trailer = trailer1?.[0]?.url || 'Sin trailer';
            const description = datam?.overview || 'Sin descripcion';
            const original_title = datam?.original_title || datam?.name;

            const dataMovie = {
                ...datam,
                original_title,
                poster_path: `https://www.themoviedb.org/t/p/original${datam?.poster_path}`,
                poster_path_respaldo: `https://www.themoviedb.org/t/p/original${datam?.poster_path}`,
                misDirectores,
                trailer,
                runtime,
                year,
                paisOrigen,
                generos,
                director,
                imdb,
                description
            };
            dispatch({
                type: types.MOVIE,
                payload: {
                    ...dataMovie,
                    typeSearch,
                    idioma,
                    myidioma: idioma
                }
            });
            dispatch({
                type: types.SEARCH,
                payload: {
                    ...datosbusqueda,
                    typeSearch,
                    idioma,
                    myidioma: idioma
                }
            });
            dispatch({ type: types.LOADING, payload: false });
            !isPoster && dispatch({ type: types.VIEW, payload: 1 });
        } else {
            dispatch({
                type: types.SEARCH,
                payload: {
                    ...datam,
                    typeSearch,
                    idioma,
                    myidioma: idioma
                }
            });
            console.log('isPoster', isPoster);
            dispatch({ type: types.LOADING, payload: false });
            isPoster
                ? dispatch({ type: types.VIEW, payload: 1 })
                : dispatch({ type: types.VIEW, payload: 0 });
        }
        setLoading(false);
    };
    useEffect(() => {
        setDatosBusquda({ ...datosbusqueda, tmdb: TMDB });
    }, [TMDB]);

    useEffect(() => {
        const ejecutar = async () => {
            if (tmdb) {
                const datam = await getMovies(datosbusqueda);

                if (!datam.overview) {
                    const datam2 = await getMovies({
                        ...datosbusqueda,
                        idioma: 'en-US'
                    });
                    const description = await getTraduction(datam2?.overview);
                    description?.text && (datam.overview = description.text);
                    description?.text && (datam.traducido = true);
                }

                if (tmdb) {
                    const misDirectores = handleDirectors(datam);
                    const trailer1 = definirTrailer(datam) ?? 'Sin Trailer';
                    const runtime = handleRuntime(datam);
                    const year =
                        datam?.release_date?.slice(0, -6) ||
                        datam?.first_air_date?.slice(0, -6) ||
                        'Sin año';
                    const paisOrigen =
                        datam?.production_countries?.[0]?.name || 'Sin pais';
                    const generos =
                        datam?.genres?.length > 0
                            ? datam.genres.flatMap(ge =>
                                  ge.name.split(' & ').map(g => ' ' + g)
                              )
                            : null;
                    const director = misDirectores.join(',') || 'Sin Director';
                    const imdb = datam?.external_ids?.imdb_id || 'Sin IMDB';
                    const trailer = trailer1?.[0]?.url || 'Sin trailer';
                    const description = datam?.overview || 'Sin descripcion';
                    const original_title = datam?.original_title || datam?.name;

                    const dataMovie = {
                        ...datam,
                        original_title,
                        poster_path: `https://www.themoviedb.org/t/p/original${datam?.poster_path}`,
                        poster_path_respaldo: `https://www.themoviedb.org/t/p/original${datam?.poster_path}`,
                        misDirectores,
                        trailer,
                        runtime,
                        year,
                        paisOrigen,
                        generos,
                        director,
                        imdb,
                        description
                    };
                    dispatch({
                        type: types.MOVIE,
                        payload: {
                            ...dataMovie,
                            typeSearch,
                            idioma,
                            myidioma: idioma
                        }
                    });
                    dispatch({
                        type: types.SEARCH,
                        payload: {
                            ...datosbusqueda,
                            typeSearch,
                            idioma,
                            myidioma: idioma
                        }
                    });
                    dispatch({ type: types.LOADING, payload: false });
                    !isPoster && dispatch({ type: types.VIEW, payload: 3 });
                } else {
                    dispatch({
                        type: types.SEARCH,
                        payload: {
                            ...datam,
                            typeSearch,
                            idioma,
                            myidioma: idioma
                        }
                    });
                    dispatch({ type: types.LOADING, payload: false });
                    !isPoster && dispatch({ type: types.VIEW, payload: 0 });
                }
            }
        };
        ejecutar();
    }, [myidioma]);

    useEffect(() => {
        setSearch(movie);
    }, [movie]);
    return (
        <>
            {searchCoincidence?.title?.rendered ? (
                <MovieCard
                    title={searchCoincidence?.title?.rendered}
                    url={searchCoincidence?.link}
                    status={searchCoincidence?.status}
                    user={searchCoincidence?.user?.name}
                    onClose={() => setPostSearchCoincidence(null)}
                />
            ) : null}
            <div></div>
            <div className={styles.container__step1}>
                {loading ? <Loading /> : null}

                <div className={styles.form__items}>
                    <Form
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit(e);
                            }
                        }}
                        className="m-2"
                        id="searchMovie"
                    >
                        <Row>
                            <Row>
                                <Col xs={12}>
                                    <Form.Check
                                        inline
                                        label="Pelicula"
                                        name="typeSearch"
                                        type="radio"
                                        value="1"
                                        checked={typeSearch === '1' && true}
                                        onChange={handleChange}
                                    />
                                    <Form.Check
                                        inline
                                        label="Serie"
                                        name="typeSearch"
                                        type="radio"
                                        value="0"
                                        checked={typeSearch === '0' && true}
                                        onChange={handleChange}
                                    />
                                    <Form.Check
                                        inline
                                        label="Colecciones"
                                        name="typeSearch"
                                        type="radio"
                                        value="2"
                                        checked={typeSearch === '2' && true}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>
                            <Col sm={6} lg={2}>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        placeholder="ID TMDB"
                                        name="tmdb"
                                        value={tmdb}
                                        onChange={handleChange}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={6} lg={7}>
                                <SearchMainCoincidence
                                    handleChangeAutoComplete={
                                        handleChangeAutoComplete
                                    }
                                    isMainSearch
                                />
                                <Form.Control
                                    style={{ display: 'none' }}
                                    type="text"
                                    placeholder="Nombre pelicula/serie"
                                    name="movie"
                                    value={movie}
                                    onChange={handleChange}
                                    size="sm"
                                />
                            </Col>
                            <Col sm={3} lg={2}>
                                <Dropdown>
                                    <Form.Select
                                        aria-label="Default select example"
                                        size="sm"
                                        onChange={e => {
                                            handleChange(e);
                                            setMyIdioma(e.target.value);
                                        }}
                                        name="idioma"
                                    >
                                        <option value="es-MX">Latino</option>
                                        <option value="en-US">Ingles</option>
                                        <option value="es-ES">
                                            Castellano
                                        </option>
                                    </Form.Select>
                                </Dropdown>
                            </Col>
                            <Col md={6} lg={1}>
                                <Button
                                    props={{
                                        size: 'sm',
                                        variant: 'flat',
                                        type: 'button',
                                        form: 'searchMovie'
                                    }}
                                    onClick={e => handleSubmit(e)}
                                >
                                    <div className="d-flex gap-2 align-items-center p-1">
                                        <FaSearch />
                                        {/* Buscar */}
                                    </div>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default Search;
