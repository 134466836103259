import React, { useState } from 'react';
import SearchPostWordpress from '../SearchPostsWoredpress/SearchPostWordpress';
import FichaCoincidence from './FichaCoincidence';
import { Stack } from '@mui/material';

const SearchMainCoincidence = ({ handleChangeAutoComplete, isMainSearch }) => {
    const [dataPost, setDataPost] = useState({});
    return (
        <>
            <Stack sx={{ width: '100%' }} spacing={1}>
                <SearchPostWordpress
                    setDataPost={setDataPost}
                    handleChangeAutoComplete={handleChangeAutoComplete}
                    isMainSearch={isMainSearch}
                />
            </Stack>
        </>
    );
};

export default SearchMainCoincidence;
