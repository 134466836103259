import React, { useEffect, useState } from 'react';
import { cuentaRegresiva } from '../../../utils/functions';
import { Chip } from '@mui/material';

const CuentaRegresiva = ({ row }) => {
    const [cuenta, setCuenta] = useState(null);
    const [isExpired, setIsExpired] = useState(false);
    const [elapsedTime, setElapsedTime] = useState({
        horas: 0,
        minutos: 0,
        segundos: 0
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            const cuentaActualizada = cuentaRegresiva(row.apar_expiration_date);
            setCuenta(cuentaActualizada);

            // Check if timer has expired
            if (
                cuentaActualizada === null ||
                (cuentaActualizada.horas === '00' &&
                    cuentaActualizada.minutos === '00' &&
                    cuentaActualizada.segundos === '00')
            ) {
                setIsExpired(true);

                // Calculate elapsed time since expiration
                const expirationDate = new Date(row.apar_expiration_date);
                const now = new Date();
                const diffInSeconds = Math.floor((now - expirationDate) / 1000);

                const hours = Math.floor(diffInSeconds / 3600);
                const minutes = Math.floor((diffInSeconds % 3600) / 60);
                const seconds = diffInSeconds % 60;

                setElapsedTime({
                    horas: hours.toString().padStart(2, '0'),
                    minutos: minutes.toString().padStart(2, '0'),
                    segundos: seconds.toString().padStart(2, '0')
                });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [row.apar_expiration_date]);

    return (
        <div
            style={{
                backgroundColor: isExpired ? '#ffebee' : 'transparent',
                padding: '4px',
                borderRadius: '4px'
            }}>
            <Chip
                label={
                    !!isExpired
                        ? `${elapsedTime.horas}:${elapsedTime.minutos}:${elapsedTime.segundos}`
                        : cuenta
                        ? `${cuenta.horas}:${cuenta.minutos}:${cuenta.segundos}`
                        : '00:00:00'
                }
                color={isExpired ? 'error' : 'default'}
            />
        </div>
    );
};

export default CuentaRegresiva;
