import { Chip, Icon } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import CustomMaterialMenu from '../../components/CustomMaterialMenu/CustomMaterialMenu';
import styled from 'styled-components';
import ButtonStyle from '../../components/button/ButtonStyle';
import moment from 'moment';
import { deletePostWordpress, searchEntriesByTitle } from '../../utils/apiwp';
import images from '../../assets/images';
import ButtonsColeections from '../../components/multipasteButtons/ButtonsColeections';
import Loading from '../../components/Loading/Loading';
import BasicDateTimePicker from '../../components/BasicDateTimePicker/BasicDateTimePicker';
import BasicModal from '../../components/MyModal/BasicModal';
import EditFecha from './components/EditFecha';
/**
 *
 * BUSCAR POR IMDB
 * https://api.themoviedb.org/3/find/tt14640242?api_key=be58b29465062a3b093bc17dacef8bf3&external_source=imdb_id
 */

const customStyles = {
    headRow: {
        style: {
            border: 'none'
        }
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '14px'
        }
    },
    rows: {
        // style: {
        //     minHeight: '56px', // Altura mínima de las filas
        //     width: '300px'
        // },
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF'
        }
    },
    pagination: {
        style: {
            border: 'none'
        }
    }
};

const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`;

const ClearButton = styled(ButtonStyle)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SearchButton = styled(ButtonStyle)`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear, handleSearch }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por Titulo"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <SearchButton type="button" onClick={() => handleSearch(filterText)}>
            Buscar
        </SearchButton>
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);

const DateCell = React.memo(({ row }) => {
    const rowId = React.useRef(row.id);
    
    React.useEffect(() => {
        rowId.current = row.id;
    }, [row.id]);

    return (
        <EditFecha
            key={`edit-fecha-${row.id}`}
            buttonTitle={moment(row.modified).format('ll')}
            postID={rowId.current}
        />
    );
});

const ListPost = ({
    arrayPost,
    totalRows,
    handlePerRowsChange,
    handlePageChange,
    loading
}) => {
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [data, setData] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);

    // Método para realizar la búsqueda
    const handleSearch = async searchTerm => {
        try {
            setSearchLoading(true);
            const results = await searchEntriesByTitle(searchTerm);
            setSearchResults(results);
        } catch (error) {
            // Manejar errores si es necesario
            console.error('Error en la búsqueda:', error.message);
        } finally {
            setSearchLoading(false);
        }
    };

    const filteredItems = data?.filter(
        item =>
            item.title?.rendered &&
            item.title?.rendered
                .toLowerCase()
                .includes(filterText.toLowerCase())
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                setSearchResults([]);
                // setData(searchResults.length > 0 ? searchResults : arrayPost);
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
                handleSearch={handleSearch}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const columns = [
        // {
        //     cell: () => <AppsIcon style={{ fill: '#43a047' }} />,
        //     width: '56px', // custom width for icon button
        //     style: {
        //         borderBottom: '1px solid #FFFFFF',
        //         marginBottom: '-1px'
        //     }
        // },
        // {
        //     name: 'ID',
        //     selector: row => row.id,
        //     sortable: true,
        //     width: '80px',
        //     style: {
        //         color: '#202124',
        //         fontSize: '14px',
        //         fontWeight: 500
        //     },
        //     wrap: true
        //     // format: row => `${row.id.slice(0, 200)}`
        // },
        {
            name: 'WP ID',
            selector: row => row.id,
            sortable: true,
            // width: '91px',
            grow: true,
            wrap: false,
            style: {
                color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            }
        },
        // {
        //     name: 'TMDB',
        //     selector: row => row?.tmdb,
        //     sortable: true,
        //     width: '91px',
        //     // grow: 1,
        //     style: {
        //         color: '#202124',
        //         fontSize: '14px',
        //         fontWeight: 500
        //     }
        // },
        {
            name: 'TITULO',
            selector: row => row.title?.rendered,
            sortable: true,
            // grow: true,
            style: {
                color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            },
            wrap: true
            // format: row => `${row.title.slice(0, 200)}`
        },
        {
            name: 'MEDIA INFO',
            selector: row =>
                row?.categorias_Etiquetas?.map(item => (
                    <Chip label={item} size="small" />
                )),
            wrap: true,
            // selector: row => ,
            sortable: true,
            // width: '91px',
            // grow: true,
            style: {
                color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            }
        },
        {
            name: 'STATUS',
            selector: row => row.status,
            // sortable: false,
            style: {
                color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            },
            grow: true,
            wrap: false
        },
        {
            name: 'FECHA',
            cell: row => <DateCell row={row} />,
            width: '150px'
        },
        {
            cell: row => (
                <ButtonsColeections
                    row={row}
                    handleDeletePost={handleDeletePost}
                />
            ),
            wrap: true
            // grow: true
            // allowOverflow: true,
            // button: true,
            // width: '370px'
        }
    ];

    const handleDeletePost = async (postId, handleShow) => {
        const dataBorrada = await deletePostWordpress(postId);

        if (dataBorrada?.status === 200) {
            const dataUpdate = data?.filter(post => post.id !== postId);
            setData(dataUpdate);
            handleShow();
        }
    };

    useEffect(() => {
        // Usa los resultados de la búsqueda si están disponibles, de lo contrario, utiliza arrayPost
        setData(searchResults.length > 0 ? searchResults : arrayPost);
    }, [arrayPost, searchResults]);

    if (searchLoading) return <Loading />;

    return (
        <>
            <DataTable
                title={
                    <div className="d-flex justify-content-start gap-2 align-items-center fw-bold">
                        <div>
                            <img
                                src={images.mis_post}
                                alt=""
                                width="45"
                                height="45"
                            />
                        </div>
                        <div>MIS POSTS</div>
                    </div>
                }
                columns={columns}
                data={filteredItems}
                customStyles={customStyles}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                highlightOnHover
                pointerOnHover
                subHeader
                paginationResetDefaultPage={resetPaginationToggle}
                subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                persistTableHead
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
            />
        </>
    );
};

export default ListPost;
