import React, { useEffect, useState } from 'react';
import { Col, Dropdown, FormControl, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import styles from '../../assets/css/step1.module.css';
import metadata from '../../metadata.json';
import checkbox from '../../checkbox.json';
import { Form as MyForm, Formik } from 'formik';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { validationSchema } from '../../config/validation-squema';
import MultiSelectField from '../../components/Form/MultiSelectField';
import { options } from '../../Audios';
import { getWordpressPostSearch } from '../../utils/apiwp';
import {
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack
} from '@mui/material';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { decodeEntity } from '../../utils/functions';
import MultiSelectFieldDelete from '../../components/Form/MultiSelectFieldDelete';
import useStep1 from './hook/useStep1';
import { toast } from 'react-toastify';
import MediaInfoDropzone from '../../components/Mediainfo/Mediainfo';
import MediaInfoComponent from '../../components/Mediainfo/MediaInfoComponent';

const Step1 = () => {
    const {
        dataAudios,
        loadingAudios,
        handleClickCreateAudio,
        handleGetAudios,
        handleDeleteAudio
    } = useStep1();
    const {
        view,
        dataAEnviar,
        movie,
        search,
        stateCalidadesSelected,
        metadataExtras
    } = useStore();
    const [coincidencias, setCoincidencias] = useState([]);
    const [loadingCoincidencias, setLoadingCoincidencias] = useState(true);
    const dispatch = useDispatch();
    const handleSubmitStep1 = values => {
        const sumar = view + 1;
        dispatch({
            type: types.DATAAENVIAR,
            payload: { ...dataAEnviar, ...values }
        });
        dispatch({ type: types.VIEW, payload: sumar });
    };

    const {
        Calidad,
        Resolucion,
        Idioma,
        Formato,
        Code,
        Extra,
        PesoArchivo,
        FormatoAudio,
        FormatoAudio2,
        FormatoAudioMediainfo,
        FormatoSubtitulosMediainfo,
        Subtitulos,
        OtrosSubs
    } = dataAEnviar || {};

    const handleBlurAudio = (value, FormatoAudio, values) => {
        if (value) {
            handleClickCreateAudio(value);

            const audioEditado = [
                ...FormatoAudio,
                { label: value, value: value }
            ];
            dispatch({
                type: types.DATAAENVIAR,
                payload: {
                    ...dataAEnviar,
                    ...values,
                    FormatoAudio: audioEditado
                }
            });
        }
    };

    /**
     *
     * BUSCAR POST REPETIDOS
     */

    const handleGetPostRepeditos = async () => {
        setLoadingCoincidencias(true);
        const data = await getWordpressPostSearch(
            movie?.title?.replace(/[^\w\s]/gi, '')
        );
        setLoadingCoincidencias(false);
        setCoincidencias(data);
    };

    useEffect(() => {
        handleGetPostRepeditos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log('CreateForm${view}', view);

    return (
        <div className={styles.container__step1}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    // Calidad:
                    //     stateCalidadesSelected?.Calidad ??
                    //     'https://i.ibb.co/YbcC4yh/60FPS.png',
                    // Resolucion:
                    //     stateCalidadesSelected?.Resolucion ??
                    //     'https://i.ibb.co/vjMt5k5/480p.png',
                    // Idioma:
                    //     stateCalidadesSelected?.Idioma ??
                    //     'https://i.ibb.co/gJWJtGn/latino.png',
                    // Formato: stateCalidadesSelected?.Formato ?? 'MKV',
                    // Code: stateCalidadesSelected?.Code ?? 'H264',
                    // Extra:
                    //     stateCalidadesSelected?.Extra ??
                    //     'https://i.ibb.co/520w63g/TUBI.png',
                    PesoArchivo: PesoArchivo ?? '',
                    FormatoAudio: FormatoAudio
                        ? FormatoAudio
                        : FormatoAudioMediainfo ?? [],
                    FormatoAudio2: FormatoAudio2 ?? '',
                    // FormatoAudioMediainfo: FormatoAudioMediainfo ?? [],
                    Subtitulos: Subtitulos
                        ? Subtitulos
                        : FormatoSubtitulosMediainfo ?? [],
                    OtrosSubs: OtrosSubs ?? ''
                }}
                validationSchema={validationSchema}
                onSubmit={values => handleSubmitStep1(values)}>
                {({
                    values,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    touched,
                    errors,
                    setFieldValue
                }) => (
                    <MyForm id={`CreateForm${view}`}>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="pesoArchivo">
                                    <Form.Label>Peso del archivo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={
                                            touched.PesoArchivo &&
                                            errors.PesoArchivo
                                                ? 'El campo es obligatorio'
                                                : 'Ej: 25.5 GB'
                                        }
                                        size="sm"
                                        name="PesoArchivo"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                            touched.PesoArchivo &&
                                            errors.PesoArchivo &&
                                            'error'
                                        }
                                        value={values.PesoArchivo}
                                    />
                                </Form.Group>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formatoAudio">
                                            <Form.Label>
                                                Formato de Audios
                                            </Form.Label>
                                            <MultiSelectFieldDelete
                                                handleDeleteAudio={
                                                    handleDeleteAudio
                                                }
                                                loadingAudios={loadingAudios}
                                                setFieldValue={setFieldValue}
                                                name="FormatoAudio"
                                                options={dataAudios}
                                                value={values.FormatoAudio}
                                            />
                                            <Form.Control
                                                type="text"
                                                placeholder={
                                                    touched.FormatoAudio2 &&
                                                    errors.FormatoAudio2
                                                        ? 'El campo es obligatorio'
                                                        : 'Ej: Español Latino 5.1 (DTS-MA), Ingles 7.1 (Atmos)'
                                                }
                                                size="sm"
                                                name="FormatoAudio2"
                                                onChange={handleChange}
                                                // onBlur={handleBlur}
                                                onBlur={() =>
                                                    handleBlurAudio(
                                                        values.FormatoAudio2,
                                                        values.FormatoAudio,
                                                        values
                                                    )
                                                }
                                                className={
                                                    touched.FormatoAudio2 &&
                                                    errors.FormatoAudio2 &&
                                                    'error'
                                                }
                                                value={values.FormatoAudio2}
                                            />
                                        </Form.Group>
                                        {/* <code>{JSON.stringify(values)}</code> */}
                                    </Col>
                                    {FormatoAudioMediainfo?.length > 0 && (
                                        <Col>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="formatoAudio">
                                                <Form.Label>
                                                    Audio Mediainfo
                                                </Form.Label>
                                                {FormatoAudioMediainfo?.map(
                                                    (item, index) => (
                                                        <Form.Control
                                                            key={index}
                                                            type="text"
                                                            size="sm"
                                                            name="FormatoAudio"
                                                            onChange={e => {
                                                                const newValue =
                                                                    e.target
                                                                        .value;
                                                                const newFormatoAudioMediainfo =
                                                                    FormatoAudioMediainfo.find(
                                                                        item2 =>
                                                                            item2.id ===
                                                                            item.id
                                                                    );
                                                                newFormatoAudioMediainfo.value =
                                                                    newValue;
                                                                newFormatoAudioMediainfo.label =
                                                                    newValue;

                                                                // Encontrar el índice del objeto a reemplazar
                                                                const indiceAReemplazar =
                                                                    FormatoAudioMediainfo.findIndex(
                                                                        objeto =>
                                                                            objeto.id ===
                                                                            item.id
                                                                    );
                                                                if (
                                                                    indiceAReemplazar !==
                                                                    -1
                                                                ) {
                                                                    FormatoAudioMediainfo[
                                                                        indiceAReemplazar
                                                                    ] =
                                                                        newFormatoAudioMediainfo;
                                                                }

                                                                const arrValues =
                                                                    FormatoAudioMediainfo;
                                                                setFieldValue(
                                                                    'FormatoAudio',
                                                                    arrValues
                                                                );
                                                            }}
                                                            value={
                                                                values
                                                                    .FormatoAudio[
                                                                    index
                                                                ]?.value
                                                            }
                                                        />
                                                    )
                                                )}
                                            </Form.Group>
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formatoAudio">
                                            <Form.Label>Subtitulos</Form.Label>
                                            <Grid
                                                container
                                                spacing={{ xs: 2, md: 0.2 }}
                                                columns={{
                                                    xs: 4,
                                                    sm: 8,
                                                    md: 4
                                                }}>
                                                {checkbox.subtitulos.map(
                                                    (check, index) => (
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            // sm={4}
                                                            // md={4}
                                                            key={index}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`subtitulo-${index}`}
                                                                label={check}
                                                                size="sm"
                                                                name="Subtitulos"
                                                                value={check}
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                checked={
                                                                    values.Subtitulos.includes(
                                                                        check
                                                                    ) || false
                                                                }
                                                            />
                                                        </Grid>
                                                    )
                                                )}
                                                {touched.Subtitulos &&
                                                errors.Subtitulos ? (
                                                    <div className="error-message">
                                                        {errors.Subtitulos}
                                                    </div>
                                                ) : null}
                                            </Grid>
                                        </Form.Group>
                                    </Col>
                                    {FormatoSubtitulosMediainfo?.length > 0 && (
                                        <Col>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="formatoAudio">
                                                <Form.Label>
                                                    Subs Mediainfo
                                                </Form.Label>
                                                {FormatoSubtitulosMediainfo?.map(
                                                    (item, index) => (
                                                        <Box key={index}>
                                                            <Form.Control
                                                                key={index}
                                                                type="text"
                                                                size="sm"
                                                                name="Subtitulos"
                                                                onChange={e => {
                                                                    const newValue =
                                                                        e.target
                                                                            .value;
                                                                    let newFormatoSubtitulosMediainfo =
                                                                        FormatoSubtitulosMediainfo.find(
                                                                            item2 =>
                                                                                item2 ===
                                                                                item
                                                                        );
                                                                    newFormatoSubtitulosMediainfo =
                                                                        newValue;

                                                                    // Encontrar el índice del objeto a reemplazar
                                                                    const indiceAReemplazar =
                                                                        FormatoSubtitulosMediainfo.findIndex(
                                                                            objeto =>
                                                                                objeto ===
                                                                                item
                                                                        );
                                                                    if (
                                                                        indiceAReemplazar !==
                                                                        -1
                                                                    ) {
                                                                        FormatoSubtitulosMediainfo[
                                                                            indiceAReemplazar
                                                                        ] =
                                                                            newFormatoSubtitulosMediainfo;
                                                                    }
                                                                    setFieldValue(
                                                                        'Subtitulos',
                                                                        FormatoSubtitulosMediainfo
                                                                    );
                                                                }}
                                                                value={
                                                                    values
                                                                        .Subtitulos[
                                                                        index
                                                                    ]
                                                                }
                                                            />
                                                            {/* <Form.Check
                                                            type="checkbox"
                                                            id={`subtituloM-${index}`}
                                                            label={check}
                                                            size="sm"
                                                            name="Subtitulos"
                                                            value={check}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            checked={
                                                                values.Subtitulos.includes(
                                                                    check
                                                                ) || false
                                                            }
                                                        /> */}
                                                        </Box>
                                                    )
                                                )}
                                            </Form.Group>
                                        </Col>
                                    )}

                                    {/* <code>{JSON.stringify(values)}</code> */}
                                </Row>
                                <Form.Group
                                    className="mb-3"
                                    controlId="formatoAudio">
                                    <Form.Control
                                        type="text"
                                        placeholder="Otros subs..."
                                        size="sm"
                                        name="OtrosSubs"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.OtrosSubs}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <button type="submit"> enviar</button> */}
                        </Row>
                    </MyForm>
                )}
            </Formik>
            {/* <MediaInfoDropzone /> */}
            <MediaInfoComponent />
        </div>
    );
};

export default Step1;
