import React, { useEffect } from 'react';
import styles from './infomovie.module.css';
import images from '../../assets/images';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, Form as MyForm } from 'formik';

import { types } from '../../store/storeReducer';
import BasicModal from '../MyModal/BasicModal';
import ListMovies from '../movie/ListMovies';
import { Box, Stack } from '@mui/material';
import { useSearchFicharPost } from '../FicharPost/store/store';
import LogosProviders from '../LogosProviders/LogosProviders';

const InfoMovie = ({ noForm = false }) => {
    const { movie: movieData, search, pelisJustWhatchID } = useStore();
    const dispatch = useDispatch();
    const { showButtonFilmaffinity } = useSearchFicharPost();

    const xCapitulo =
        search?.typeSearch === '0' && movieData?.runtime ? 'x Capitulo' : '';

    // const misDirectores = handleDirectors(movieData);
    // const trailer = definirTrailer(movieData) ?? 'Sin trailer';
    // const runtime = handleRuntime(movieData);

    const handleSubmitOnBlur = e => {
        if (e.target.name === 'trailer') {
            dispatch({
                type: types.MOVIE,
                payload: { ...movieData, trailer: e.target.value }
            });
            dispatch({
                type: types.DATAAENVIAR,
                payload: { ...movieData, trailer: e.target.value }
            });
        }
        if (e.target.name === 'titleOriginal') {
            dispatch({
                type: types.MOVIE,
                payload: {
                    ...movieData,
                    original_title: e.target.value
                }
            });
            dispatch({
                type: types.DATAAENVIAR,
                payload: { ...movieData, original_title: e.target.value }
            });
        }
        if (e.target.name === 'year') {
            dispatch({
                type: types.MOVIE,
                payload: {
                    ...movieData,
                    year: e.target.value
                }
            });
            dispatch({
                type: types.DATAAENVIAR,
                payload: { ...movieData, year: e.target.value }
            });
        }
        if (e.target.name === 'paisOrigen') {
            dispatch({
                type: types.MOVIE,
                payload: {
                    ...movieData,
                    production_countries: [{ name: e.target.value }]
                }
            });
            dispatch({
                type: types.DATAAENVIAR,
                payload: {
                    ...movieData,
                    production_countries: [{ name: e.target.value }]
                }
            });
        }
        if (e.target.name === 'runtime') {
            dispatch({
                type: types.MOVIE,
                payload: {
                    ...movieData,
                    runtime: e.target.value
                }
            });
            dispatch({
                type: types.DATAAENVIAR,
                payload: { ...movieData, runtime: e.target.value }
            });
        }
        if (e.target.name === 'generos') {
            dispatch({
                type: types.MOVIE,
                payload: {
                    ...movieData,
                    generos: e.target.value
                }
            });
            dispatch({
                type: types.DATAAENVIAR,
                payload: { ...movieData, generos: e.target.value }
            });
        }
        if (e.target.name === 'director') {
            dispatch({
                type: types.MOVIE,
                payload: {
                    ...movieData,
                    misDirectores: [e.target.value],
                    director: e.target.value
                }
            });
            dispatch({
                type: types.DATAAENVIAR,
                payload: {
                    ...movieData,
                    misDirectores: [e.target.value],
                    director: e.target.value
                }
            });
        }
        if (e.target.name === 'imdb') {
            dispatch({
                type: types.MOVIE,
                payload: {
                    ...movieData,
                    imdb: e.target.value
                }
            });
            dispatch({
                type: types.DATAAENVIAR,
                payload: { ...movieData, imdb: e.target.value }
            });
        }
        if (e.target.name === 'description') {
            dispatch({
                type: types.MOVIE,
                payload: {
                    ...movieData,
                    overview: e.target.value
                }
            });
            dispatch({
                type: types.DATAAENVIAR,
                payload: { ...movieData, overview: e.target.value }
            });
        }
    };

    return (
        <div className={styles.container__infomovie}>
            <Stack mt={1.2}>
                {showButtonFilmaffinity && (
                    <BasicModal
                        buttonTitle={'Buscar en Filmaffinity'}
                        sxButton={sxButton}
                        styles={styleModal}
                        free>
                        <ListMovies isPoster isFilmaffinity />
                    </BasicModal>
                )}
            </Stack>

            <div className={styles.infomovie__img}>
                <img
                    src={
                        movieData?.poster_path
                            ? movieData?.poster_path
                            : images.image870x1315
                    }
                    alt="image870x1315"
                    style={{ width: noForm ? '100%' : '50%' }}
                />
            </div>

            {!noForm && (
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        titleOriginal:
                            movieData?.original_title ||
                            movieData?.name ||
                            'Sin titulo',
                        year: movieData?.year || 'Sin año',
                        paisOrigen: movieData?.paisOrigen || 'Sin pais',
                        runtime:
                            `${movieData?.runtime} ${xCapitulo}` ||
                            'Sin minutos',
                        generos: movieData?.generos,
                        director: movieData?.misDirectores,
                        imdb: movieData?.imdb || 'Sin IMDB',
                        trailer:
                            movieData?.trailer ||
                            movieData?.selectedTrailer?.url,
                        description:
                            movieData?.overview || movieData?.description
                    }}>
                    {({
                        values,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        errors,
                        touched
                    }) => (
                        <MyForm>
                            <Row className="mt-3">
                                <Col xs={8}>
                                    <Form.Control
                                        type="text"
                                        placeholder={
                                            touched.titleOriginal &&
                                            errors.titleOriginal
                                                ? 'El campo es obligatorio'
                                                : 'Titulo Original'
                                        }
                                        className={
                                            touched.titleOriginal &&
                                            errors.titleOriginal &&
                                            'error'
                                        }
                                        name="titleOriginal"
                                        value={values.titleOriginal}
                                        onChange={handleChange}
                                        onBlur={e => {
                                            handleBlur(e);
                                            handleSubmitOnBlur(e);
                                        }}
                                        size="sm"
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Año"
                                        name="year"
                                        value={values.year}
                                        onChange={handleChange}
                                        onBlur={e => {
                                            handleBlur(e);
                                            handleSubmitOnBlur(e);
                                        }}
                                        size="sm"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={6}>
                                    <Form.Control
                                        type="text"
                                        placeholder="País de origen"
                                        name="paisOrigen"
                                        value={values.paisOrigen}
                                        onChange={handleChange}
                                        onBlur={e => {
                                            handleBlur(e);
                                            handleSubmitOnBlur(e);
                                        }}
                                        size="sm"
                                    />
                                </Col>
                                <Col
                                    xs={2}
                                    className="d-flex justify-content-center gap-2 align-items-center">
                                    <div>
                                        <img
                                            src={movieData?.countryImageUrl}
                                            alt={movieData?.paisOrigen}
                                            title={movieData?.paisOrigen}
                                            width={'100%'}
                                            height={'85%'}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Duración"
                                        name="runtime"
                                        value={values.runtime}
                                        onChange={handleChange}
                                        onBlur={e => {
                                            handleBlur(e);
                                            handleSubmitOnBlur(e);
                                        }}
                                        size="sm"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Géneros"
                                        name="generos"
                                        value={values.generos}
                                        onChange={handleChange}
                                        onBlur={e => {
                                            handleBlur(e);
                                            handleSubmitOnBlur(e);
                                        }}
                                        size="sm"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={8}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Director"
                                        value={values.director}
                                        onChange={handleChange}
                                        onBlur={e => {
                                            handleBlur(e);
                                            handleSubmitOnBlur(e);
                                        }}
                                        name="director"
                                        size="sm"
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="IMDB ID"
                                        value={values.imdb}
                                        onChange={handleChange}
                                        onBlur={e => {
                                            handleBlur(e);
                                            handleSubmitOnBlur(e);
                                        }}
                                        name="imdb"
                                        size="sm"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Tráiler Link"
                                        value={values.trailer}
                                        onChange={handleChange}
                                        onBlur={e => {
                                            handleBlur(e);
                                            handleSubmitOnBlur(e);
                                        }}
                                        name="trailer"
                                        size="sm"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    {movieData?.traducido && (
                                        <small>Traducido</small>
                                    )}
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1">
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={e => {
                                                handleBlur(e);
                                                handleSubmitOnBlur(e);
                                            }}
                                            name="description"
                                            size="sm"
                                            placeholder="Sinopsis"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </MyForm>
                    )}
                </Formik>
            )}

            {noForm && (
                <div className="mt-1">
                    <LogosProviders providers={pelisJustWhatchID} />
                </div>
            )}
        </div>
    );
};

export default InfoMovie;

const sxButton = {
    sx: {
        backgroundColor: '#260505',
        color: '#F9E8C9',
        fontWeight: 'bold',
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        '&:hover': {
            backgroundColor: '#2f246b',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained'
};

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55%',
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 12,
    p: 1,
    overflowY: 'auto', // Agregar scroll vertical cuando sea necesario
    maxHeight: '90vh' // Limitar la altura para evitar que el modal sea demasiado grande
};
