import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import './SearchPostWordpress.css';
import {
    fetchUserWP,
    getpostWordpress,
    getWordpressSearch
} from '../../utils/apiwp';
import { useSearchCoincidence } from '../SearchCoincidence/store/store';

const decodeHtmlEntities = text => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
};

const SearchPostWordpress = ({
    setDataPost,
    handleChangeAutoComplete = false,
    isMainSearch
}) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState();
    const { setPostSearchCoincidence } = useSearchCoincidence();

    const handleSearch = async query => {
        isMainSearch && handleChangeAutoComplete(query);
        setIsLoading(true);
        const results = await getWordpressSearch(query);
        setOptions(
            results.map(item => ({
                ...item,
                title: decodeHtmlEntities(item.title)
            }))
        );
        setIsLoading(false);
    };

    const getPost = async postId => {
        const result = await getpostWordpress(postId);
        const user = await fetchUserWP({ userId: result?.author });

        setDataPost({ ...result, user });
        setPostSearchCoincidence({ ...result, user });
    };

    useEffect(() => {
        if (selected) {
            getPost(selected?.id);
            // isMainSearch && handleChangeAutoComplete(selected?.title);
        }
    }, [selected]);

    const myProps = isMainSearch
        ? {
              size: 'sm'
          }
        : {};

    return (
        <AsyncTypeahead
            // defaultInputValue={textABuscar}\
            {...myProps}
            className={selected ? 'custom-dropdown' : ''}
            autoFocus
            clearButton
            placeholder={
                isMainSearch ? 'Buscar peliculas/series' : 'Buscar post'
            }
            ignoreDiacritics={true}
            isLoading={isLoading}
            emptyLabel="No se encontraron resultados"
            labelKey={option => `${option?.title}`}
            id="search"
            onChange={selected => {
                setSelected(selected[0]);
            }}
            onSearch={query => {
                handleSearch(query);
            }}
            options={options}
        />
    );
};

export default SearchPostWordpress;
