import {
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import DragAndDrop from '../DragArea/DragAndDrop';
import useCropper from './hooks/useCropper';
import { Cropper } from 'react-cropper';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import ImageWithDeleteButton from './ImageWithDeleteButton ';
import { ReactSortable } from 'react-sortablejs';

import 'cropperjs/dist/cropper.css';
// import Button from '../button/Button';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const arrayCapturas = [
    'captura1',
    'captura2',
    'captura3',
    'captura4',
    'captura5'
];

const LayoutCrop = () => {
    const {
        imageFile,
        cropperRef,
        cropBoxData,
        imageCropUrl,
        loadingSave,
        newCropUrl,
        arrayCropsSize,
        setImageCropUrl,
        handleFileChange,
        handleChangeCropData,
        handleSetBoxData,
        handleCrop,
        onCrop,
        handleSaveCrop,
        handleCropSaveSize,
        handleDeleteCrop,
        handleSetStep2,
        setNameCropBoxData,
        handleChangeNameCropBoxData,
        handleDeleteImageCropUrl
    } = useCropper();

    return (
        <>
            <Paper elevation={0} sx={{ width: '100%' }}>
                <Box>
                    {/* <code>{JSON.stringify(newCropUrl)}</code> */}

                    <DragAndDrop
                        title={'Arrastra una captura'}
                        accept="image/*"
                        handleChange={handleFileChange}
                    />
                </Box>
                <Box>
                    {newCropUrl?.length > 0 &&
                        newCropUrl?.map((url, index) => (
                            <TextField
                                key={index}
                                fullWidth
                                sx={{ marginTop: 1 }}
                                hiddenLabel
                                id="filled-hidden-label-small"
                                // defaultValue="Small"
                                // variant="filled"
                                variant="standard"
                                size="small"
                                value={url}
                            />
                        ))}
                </Box>

                <Grid container spacing={2} sx={{ marginTop: '0px' }}>
                    <Grid item xs={10}>
                        <Stack direction={'row'} gap={1}>
                            <Box width={'100%'}>
                                <Cropper
                                    src={imageFile}
                                    style={{ height: '70%', width: '100%' }}
                                    ref={cropperRef}
                                    initialAspectRatio={1}
                                    preview=".img-preview2"
                                    viewMode={1}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                    guides={true}
                                    crop={onCrop}
                                    zoomable={false}
                                />
                            </Box>
                            <Stack width={'100%'} gap={1}>
                                <Stack
                                    direction={'row'}
                                    gap={1}
                                    justifyContent={'center'}
                                    width={'100%'}>
                                    <Button
                                        className="btn-primary"
                                        fullWidth
                                        size="small"
                                        variant="contained"
                                        disableElevation
                                        color="secondary"
                                        onClick={handleCrop}>
                                        Recortar
                                    </Button>
                                    <LoadingButton
                                        className="btn-primary"
                                        loading={loadingSave}
                                        fullWidth
                                        size="small"
                                        variant="contained"
                                        disableElevation
                                        color="secondary"
                                        onClick={handleSaveCrop}>
                                        Recortar y enviar
                                    </LoadingButton>
                                </Stack>
                                {/* {newCropUrl && (
                                    <Stack
                                        direction={'row'}
                                        gap={1}
                                        justifyContent={'center'}
                                        width={'100%'}>
                                        {arrayCapturas.map((captura, index) => (
                                            <Button
                                                className="btn-primary"
                                                key={index}
                                                startIcon={
                                                    <PersonalVideoIcon />
                                                }
                                                fullWidth
                                                size="small"
                                                variant="contained"
                                                disableElevation
                                                color="secondary"
                                                onClick={() =>
                                                    handleSetStep2(
                                                        captura,
                                                        newCropUrl
                                                    )
                                                }>
                                                {index + 1}
                                            </Button>
                                        ))}
                                    </Stack>
                                )} */}
                                {/* {JSON.stringify(imageCropUrl)} */}
                                <ReactSortable
                                    animation={200}
                                    list={imageCropUrl}
                                    setList={setImageCropUrl}>
                                    {imageCropUrl?.map((url, index) => (
                                        <Box key={`${index}-${url}`}>
                                            <ImageWithDeleteButton
                                                imageUrl={url}
                                                onClick={() =>
                                                    handleDeleteImageCropUrl(
                                                        index
                                                    )
                                                }
                                            />
                                            {/* <img src={url} alt="" /> */}
                                        </Box>
                                    ))}
                                </ReactSortable>
                                {/* <Box>
                                    <img src={imageCropUrl} alt="" />
                                </Box> */}
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={2}>
                        <Item>
                            <Stack gap={2}>
                                <TextField
                                    size="small"
                                    type="number"
                                    id="outlined-basic"
                                    label="Ancho"
                                    variant="outlined"
                                    value={cropBoxData?.width ?? 0}
                                    onChange={e =>
                                        handleChangeCropData(
                                            Number(e.target.value),
                                            'width'
                                        )
                                    }
                                />
                                <TextField
                                    size="small"
                                    type="number"
                                    id="outlined-basic"
                                    label="Alto"
                                    variant="outlined"
                                    value={cropBoxData?.height ?? 0}
                                    onChange={e =>
                                        handleChangeCropData(
                                            Number(e.target.value),
                                            'height'
                                        )
                                    }
                                />
                                <TextField
                                    size="small"
                                    type="number"
                                    id="outlined-basic"
                                    label="Posición X"
                                    variant="outlined"
                                    value={cropBoxData?.left ?? 0}
                                    onChange={e =>
                                        handleChangeCropData(e, 'left')
                                    }
                                />
                                <TextField
                                    size="small"
                                    type="number"
                                    id="outlined-basic"
                                    label="Posición Y"
                                    variant="outlined"
                                    value={cropBoxData?.top ?? 0}
                                    onChange={e =>
                                        handleChangeCropData(
                                            Number(e.target.value),
                                            'top'
                                        )
                                    }
                                />
                                <TextField
                                    size="small"
                                    type="text"
                                    id="outlined-basic"
                                    label="Nombre"
                                    variant="outlined"
                                    value={cropBoxData?.name}
                                    onChange={handleChangeNameCropBoxData}
                                />
                                <Button
                                    className="btn-primary"
                                    size="small"
                                    startIcon={<SaveIcon />}
                                    disableElevation
                                    variant="contained"
                                    onClick={e => handleCropSaveSize(e)}>
                                    Guardar
                                </Button>
                            </Stack>
                        </Item>
                        {arrayCropsSize.length > 0 ? (
                            <Stack gap={1} marginTop={1}>
                                <Item>
                                    {arrayCropsSize.map((size, index) => (
                                        <Stack
                                            key={index}
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            gap={1}>
                                            <Box width={'100%'}>
                                                <Button
                                                    className="btn-primary"
                                                    key={index}
                                                    fullWidth
                                                    size="small"
                                                    disableElevation
                                                    variant="contained"
                                                    onClick={() =>
                                                        handleSetBoxData(size)
                                                    }>
                                                    {size.name}
                                                </Button>
                                            </Box>
                                            <Box>
                                                <IconButton
                                                    aria-label="save"
                                                    onClick={() =>
                                                        handleDeleteCrop(index)
                                                    }>
                                                    <DeleteIcon fontSize="inherit" />
                                                </IconButton>
                                            </Box>
                                        </Stack>
                                    ))}
                                </Item>
                            </Stack>
                        ) : null}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default LayoutCrop;
