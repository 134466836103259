import axios from 'axios';
import {
    REACT_APP_APIKEY_THEMOVIES,
    REACT_APP_DOMINIO_APITHEMOVIES
} from '../env';

const API = REACT_APP_DOMINIO_APITHEMOVIES;
const APIKEY = REACT_APP_APIKEY_THEMOVIES;
const TYPE_SEARCH = {
    1: 'movie',
    0: 'tv',
    2: 'collection'
};

// https://api.themoviedb.org/3/movie/718930?api_key=be58b29465062a3b093bc17dacef8bf3&language=es-ES&append_to_response=images&include_image_language=en,null

export const getMovieImdb = async imdb => {
    try {
        const { data } = await axios.get(
            `http://api.themoviedb.org/3/find/${imdb}?api_key=${APIKEY}&external_source=imdb_id`
        );

        if (data?.movie_results.length > 0) {
            return data.movie_results;
        }
        return data?.tv_results;
    } catch (error) {}
};

const getTrailer = videos => {
    if (!videos || !videos.results || videos.results.length === 0) return null;

    // Filter only trailer type videos
    const trailers = videos.results.filter(
        video => video.type.toLowerCase() === 'trailer'
    );
    if (trailers.length === 0) return null;

    // Priority: Spanish (Latino) > Spanish > English > Any other language
    const latinoTrailer = trailers.find(
        video =>
            video.iso_639_1 === 'es' &&
            video.name.toLowerCase().includes('latino')
    );
    if (latinoTrailer) return latinoTrailer;

    const spanishTrailer = trailers.find(video => video.iso_639_1 === 'es');
    if (spanishTrailer) return spanishTrailer;

    const englishTrailer = trailers.find(video => video.iso_639_1 === 'en');
    if (englishTrailer) return englishTrailer;

    // If no Spanish or English trailer found, return the first available trailer
    return trailers[0];
};

export const getMovies = async datosbusqueda => {
    const { tmdb, movie, typeSearch, idioma } = datosbusqueda;
    let url;
    const type = TYPE_SEARCH[typeSearch] || 'movie';

    if (tmdb) {
        // Hacemos dos solicitudes: una para los datos en el idioma preferido y otra para todos los videos
        const movieDataUrl = `${API}${type}/${tmdb}?api_key=${APIKEY}&language=${idioma}&append_to_response=credits,external_ids,images&include_image_language=en,null`;

        try {
            if (typeSearch === '2') {
                // Si es una colección, solo hacemos la solicitud de datos básicos
                const movieResponse = await axios.get(movieDataUrl);
                return movieResponse.data;
            }

            // Para películas y series, hacemos ambas solicitudes
            const videosUrl = `${API}${type}/${tmdb}/videos?api_key=${APIKEY}&include_video_language=en,es,fr,de,tr,it,nl,pl,pt,sv,cs,da,hu,ar,hi,ja,ko,ru,vi,zh`;
            const [movieResponse, videosResponse] = await Promise.all([
                axios.get(movieDataUrl),
                axios.get(videosUrl)
            ]);

            let finalVideosData = videosResponse.data;

            // Si no hay videos, intentar obtenerlos en el idioma original
            if (
                !finalVideosData.results ||
                finalVideosData.results.length === 0
            ) {
                const originalLanguage = movieResponse.data.original_language;
                const originalLanguageVideosUrl = `${API}${type}/${tmdb}/videos?api_key=${APIKEY}&include_video_language=${originalLanguage}`;
                try {
                    const originalLanguageResponse = await axios.get(
                        originalLanguageVideosUrl
                    );
                    finalVideosData = originalLanguageResponse.data;
                } catch (error) {
                    console.error(
                        'Error getting videos in original language:',
                        error
                    );
                }
            }

            const data = {
                ...movieResponse.data,
                videos: finalVideosData
            };

            // Get the best available trailer
            const trailer = getTrailer(data.videos);
            if (trailer) {
                data.selectedTrailer = trailer;
            }

            if (typeSearch === '0') {
                const result = await axios.get(
                    `${API}tv/${tmdb}/season/1/episode/1?api_key=${APIKEY}&append_to_response=credits`
                );
                return { ...data, credits: { ...result.data } };
            }

            return data;
        } catch (error) {
            return error;
        }
    } else {
        // Para búsquedas por nombre mantenemos la lógica original
        url = `${API}search/${type}?api_key=${APIKEY}&language=${idioma}&query=${movie}`;
        try {
            const { data } = await axios.get(url);
            return data;
        } catch (error) {
            return error;
        }
    }
};

export const getDirectors = async datosbusqueda => {
    const { tmdb, typeSearch } = datosbusqueda;
    let url;
    const type = typeSearch === '1' ? 'movie' : 'tv';

    if (tmdb) {
        url = `${API}${type}/${tmdb}?api_key=${APIKEY}&append_to_response=credits`;
    }

    try {
        const { data } = await axios.get(url);
        return data;
    } catch (error) {
        return error;
    }
};

// https://api.themoviedb.org/3/movie/718930?api_key=be58b29465062a3b093bc17dacef8bf3&language=es-ES&append_to_response=images&include_image_language=en,null
export const getCapturas = async (imdb, typeSearch) => {
    const isMoviOrSerie = typeSearch === '1' ? 'movie' : 'tv';
    try {
        const result = await axios.get(
            `${API}${isMoviOrSerie}/${imdb}?api_key=${APIKEY}&language=es-ES&append_to_response=images&include_image_language=en,null`
        );
        return result;
    } catch (error) {
        return error.response;
    }
};
