import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import {
    convertBlobToImageFile,
    getLocalStorage
} from '../../../utils/functions';
import {
    subirPoster,
    subirPosterImgur,
    subirPosterPixhostFile
} from '../../../utils/subirimg';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { useDispatch, useStore } from '../../../store/StoreProvider';
import { types } from '../../../store/storeReducer';
import { toast } from 'react-toastify';

// funcion para redondear valores de un objeto
const roundObject = obj => {
    const newObj = {};
    for (const key in obj) {
        newObj[key] = Math.round(obj[key]);
    }
    return newObj;
};

const defaultSrc =
    'https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg';

const useCropper = () => {
    const cropperRef = useRef(null);
    const [imageFile, setImageFile] = useState(defaultSrc);
    const [cropBoxData, setCropBoxData] = useState();
    const [imageCropUrl, setImageCropUrl] = useState([]);
    const [loadingSave, setLoadingSave] = useState(false);
    const [newCropUrl, setNewCropUrl] = useState([]);
    // const [arrayCropsSize, setArrayCropsSize] = useState([]);
    const cropData = getLocalStorage('crop');
    const [arrayCropsSize, setArrayCropsSize] = useLocalStorage(
        'crop',
        cropData ?? []
    );
    const { dataAEnviar } = useStore();
    const dispatch = useDispatch();

    const handleFileChange = e => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImageFile(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const handleChangeCropData = (e, key) => {
        setCropBoxData(prev => ({
            ...cropBoxData,
            [key]: e
        }));
        cropperRef.current?.cropper.setCropBoxData({
            ...cropBoxData,
            [key]: e
        });
    };

    const handleSetBoxData = data => {
        if (cropperRef.current && imageFile) {
            cropperRef.current?.cropper.setCropBoxData(data);
        }
    };

    const handleCrop = () => {
        if (cropperRef.current && imageFile) {
            const cropper = cropperRef.current?.cropper;
            cropper.getCroppedCanvas().toBlob(async blob => {
                const imageUrl = URL.createObjectURL(blob);
                setImageCropUrl([...imageCropUrl, imageUrl]);
                setNewCropUrl([]);
                // setImageCropUrl(imageUrl);

                // const imagen = await convertBlobToImageFile(imageUrl);
                // console.log('imagen', imagen);

                // const data = await subirPosterImgur(imagen);
                // console.log(data);
            });
        }
    };
    const handleSaveCrop = async (imgAll = imageCropUrl) => {
        try {
            setNewCropUrl([]);
            let i = 1;
            
            // Usar el array de imageCropUrl directamente
            for (const url of imageCropUrl) {
                if (!url || !url.startsWith('blob:')) continue;
                console.log('Procesando URL:', url);

                try {
                    const img = await convertBlobToImageFile(url);
                    console.log('Imagen convertida:', img);
                    const link = await handleSubirPoster(img);
                    console.log('Link obtenido:', link);
                    
                    if (link) {
                        handleSetStep2(`captura${i}`, link);
                        i++;
                        
                        toast.success(link, {
                            position: 'bottom-left',
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light'
                        });
                    } else {
                        toast.error('Error al subir la imagen', {
                            position: 'bottom-left',
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light'
                        });
                    }
                } catch (error) {
                    console.error('Error procesando imagen:', error);
                    toast.error('Error al procesar la imagen', {
                        position: 'bottom-left',
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light'
                    });
                }
            }
        } catch (error) {
            console.error('Error general en handleSaveCrop:', error);
            toast.error('Error general al procesar las imágenes', {
                position: 'bottom-left',
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
        }
    };

    const onCrop = () => {
        // if (typeof cropperRef.current?.cropper !== "undefined") {
        const data = cropperRef.current?.cropper.getCropBoxData();
        setCropBoxData(roundObject(data));
        // }
    };

    const handleSubirPoster = async imagen => {
        try {
            setLoadingSave(true);

            let url;
            const data = await subirPosterImgur(imagen);
            console.log('data', data);

            if (data?.status === 200) {
                url = data?.data?.link;
            }

            if (data?.status !== 200) {
                const data2 = await subirPosterPixhostFile(imagen);
                console.log('data2', data2);

                if (data2?.status === 200) {
                    url = data2?.data?.url;
                }

                if (data2?.status !== 200) {
                    const data3 = await subirPoster(imagen);
                    console.log('data3', data3);

                    if (data3?.status === 200) {
                        url = data3?.data?.link;
                    }
                }
            }

            setNewCropUrl(pre => [...pre, url]);
            // setNewCropUrl(pre => [...pre, data?.data?.link]);
            // handleSetStep2('captura1', data?.data?.link);
            setLoadingSave(false);
            return url;
        } catch (error) {
            console.log(error);
            setLoadingSave(false);
            return null;
        }
    };

    const handleCropSaveSize = () => {
        if (cropperRef.current && imageFile && arrayCropsSize) {
            setArrayCropsSize([...arrayCropsSize, cropBoxData]);
        } else {
            setArrayCropsSize([cropBoxData]);
        }
    };

    const handleDeleteCrop = index => {
        setArrayCropsSize(arrayCropsSize.filter((_, i) => i !== index));
    };

    const handleSetStep2 = (numeroCaptura, link) => {
        dispatch({
            type: types.DATAAENVIAR,
            payload: { ...dataAEnviar, ...{ [numeroCaptura]: link } }
        });
    };

    const handleChangeNameCropBoxData = (e, key) => {
        setCropBoxData(prev => ({
            ...cropBoxData,
            name: e.target.value
        }));
    };

    const handleDeleteImageCropUrl = index => {
        setImageCropUrl(imageCropUrl.filter((_, i) => i !== index));
    };

    return {
        imageFile,
        cropperRef,
        cropBoxData,
        imageCropUrl,
        loadingSave,
        newCropUrl,
        arrayCropsSize,
        setImageCropUrl,
        handleFileChange,
        handleChangeCropData,
        handleSetBoxData,
        handleCrop,
        onCrop,
        handleSaveCrop,
        handleCropSaveSize,
        handleDeleteCrop,
        handleSetStep2,
        handleChangeNameCropBoxData,
        handleDeleteImageCropUrl
    };
};

export default useCropper;
