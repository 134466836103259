import { useEffect, useRef, useState } from 'react';
import {
    Alert,
    Badge,
    Box,
    IconButton,
    Popper,
    Fade,
    Button,
    Typography,
    FormGroup,
    FormControlLabel,
    Switch,
    Stack
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import CheckIcon from '@mui/icons-material/Check';

import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import { toast } from 'react-toastify';
import useNotification from './hook/useNotification';
import { URLS_LOCALES, motivoOptions } from '../../constants';

const types = ['success', 'info', 'warning', 'error'];
const initialData = {
    typeNotify: 'post_reported',
    title: 'Post Reportado',
    text: 'Liga de la Justicia – Crisis en Tierras Infinitas – Parte 2 (2024) [2160p] 4k WEB-DL HDR [Latino-Inglés]',
    url: 'https://www.google.com'
};

export default function DescriptionAlerts({ event, notificationsAll }) {
    const { handleUpdateNotification } = useNotification();
    const {
        notifications,
        clear,
        markAllAsRead,
        markAsRead,
        unreadCount,
        add
    } = useNotificationCenter({
        data: notificationsAll.map(notification => ({
            ...notification,
            content: BoxPostReported(notification.content)
        })),
        sort: (l, r) => r.data.id - l.data.id
        // filter: item => item.data.exclude === false
    });

    const [showUnreadOnly, setShowUnreadOnly] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const popperRef = useRef(null);

    // console.log('nnn', notifications);
    // console.log('notificationsAll', notificationsAll);

    const addNotification = event => {
        toast(BoxPostReported(event), {
            type: event.typeN || 'info',
            position: 'bottom-right',
            autoClose: 6000,
            data: { id: event.id, content: BoxPostReported(event) }
        });
    };

    const toggleNotificationCenter = event => {
        setAnchorEl(event.currentTarget);
        setIsOpen(!isOpen);
    };

    const toggleFilter = e => {
        setShowUnreadOnly(!showUnreadOnly);
    };

    const handleChangeColorAlert = notification => {
        !notification.read && handleUpdateNotification(notification.data.id);
        markAsRead(notification.id);
        notification.type = 'success';
    };
    const handleChangeColorAlertAll = () => {
        markAllAsRead();
        // notification.type = 'success';
    };

    useEffect(() => {
        if (event) {
            addNotification(event);
        }
    }, [event]);

    // useEffect(() => {
    //     notificationsAll.forEach(notification => {
    //         add({
    //             ...notification,
    //             content: BoxPostReported(notification.content)
    //         });
    //     });
    // }, [notificationsAll]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                popperRef.current &&
                !popperRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popperRef]);

    const nnn = notificationsAll.filter(
        i => i.content.title !== 'post_separated'
    );

    return (
        <Box sx={{ margin: '8px' }}>
            <IconButton size="large" onClick={toggleNotificationCenter}>
                <Badge badgeContent={unreadCount} color="ochre">
                    <NotificationsIcon color="white" />
                </Badge>
            </IconButton>
            {/* <button onClick={addNotification}>Add notification</button> */}

            <Popper
                open={isOpen}
                anchorEl={anchorEl}
                transition
                ref={popperRef}
                sx={{ zIndex: 9999 }}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box>
                            <Box
                                sx={{
                                    background: '#DAE1E7',
                                    padding: '8px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderRadius: '8px 8px 0 0'
                                }}>
                                <Typography
                                    variant="h5"
                                    color="#000"
                                    sx={{ fontWeight: 600 }}>
                                    Notificaciones
                                </Typography>
                                <FormGroup sx={{ color: '#000' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="secondary"
                                                onChange={toggleFilter}
                                                checked={showUnreadOnly}
                                            />
                                        }
                                        label="Mostrar solo no leídas"
                                    />
                                </FormGroup>
                            </Box>
                            <Stack
                                sx={{
                                    height: '400px',
                                    width: 'min(60ch, 100ch)',
                                    padding: '12px',
                                    background: '#DAE1E7',
                                    // borderRadius: '8px',
                                    borderRadius: '0 0 8px 8px',
                                    overflowY: 'auto'
                                }}
                                spacing={2}>
                                {(!notifications.length ||
                                    (unreadCount === 0 && showUnreadOnly)) && (
                                    <h4>
                                        Nada por aquí{' '}
                                        <span
                                            role="img"
                                            aria-label="dunno what to put">
                                            🎉
                                        </span>
                                    </h4>
                                )}
                                {(showUnreadOnly
                                    ? notifications.filter(v => !v.read)
                                    : notifications
                                ).map(notification => {
                                    return (
                                        <Alert
                                            onMouseEnter={() =>
                                                handleChangeColorAlert(
                                                    notification
                                                )
                                            }
                                            severity={
                                                notification?.type === 'default'
                                                    ? 'info'
                                                    : notification?.type ||
                                                      'info'
                                            }
                                            action={
                                                notification?.read ? (
                                                    <CheckIcon />
                                                ) : (
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="upload picture"
                                                        component="span"
                                                        onClick={() =>
                                                            markAsRead(
                                                                notification.id
                                                            )
                                                        }>
                                                        <MarkChatReadIcon />
                                                    </IconButton>
                                                )
                                            }>
                                            {/* {notification?.content
                                                ?.typeNotify === 'post_reported'
                                                ? BoxPostReported(notification)
                                                : notification?.content} */}
                                            {notification?.content}
                                        </Alert>
                                    );
                                })}
                            </Stack>
                            {/* <Box
                                sx={{
                                    background: '#DAE1E7',
                                    padding: '8px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                <Button variant="contained" onClick={clear}>
                                    Clear All
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleChangeColorAlertAll}>
                                    Marcar todo como leído
                                </Button>
                            </Box> */}
                        </Box>
                    </Fade>
                )}
            </Popper>
        </Box>
    );
}

const BoxPostReported = notification => {
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 17,
                    fontWeight: 'bold'
                }}>
                {motivoOptions[notification?.title]}
            </Typography>
            <Stack gap={1}>
                <Typography
                    sx={{
                        fontSize: 14,
                        textDecoration: 'none',
                        color: '#000'
                    }}
                    component={'a'}
                    href={
                        URLS_LOCALES[notification?.title] || '/post-reportados'
                    }
                    // target="_blank"
                >
                    {`${notification?.text}`}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 10,
                        textDecoration: 'none',
                        color: '#000'
                    }}
                    component={'span'}>
                    {`${notification?.uploader}`}
                </Typography>
            </Stack>
        </Box>
    );
};
