import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Grid,
    Stack,
    MenuItem,
    TextField
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import React, { useEffect } from 'react';
import Layout from './components/Layout';
import { useState } from 'react';
import SearchPostWordpress from '../SearchPostsWoredpress/SearchPostWordpress';
import { makePostRequest } from '../../utils/objectFormat';
import {
    extraerEnlaceFree,
    extraerEnlaceVip,
    extraerUrlsDeTextos,
    getLocalStorage,
    obtenerNombresDeHost,
    searchDataUser
} from '../../utils/functions';
import { getCategoriaWordpress, getpostsWordpress } from '../../utils/apiwp';
import images from '../../assets/images';
import { toast, Toaster } from 'react-hot-toast';
import { useStore } from '../../store/StoreProvider';
import UnstyledTextareaIntroduction from './components/TextArea';
import DragAndDrop from '../DragArea/DragAndDrop';
import { handleSubirPoster, subirPosterImgur } from '../../utils/subirimg';

const tipoDePublicaion = [
    { value: '#NuevoPost', label: 'Post nuevo', selected: true },
    { value: '#NuevaSerie', label: 'Nueva Serie' },
    { value: '#NuevoCapitulo', label: 'Nuevo capitulo (Series)' },
    { value: '#NuevaTemporada', label: 'Nueva temporada (Series)' },
    { value: '#Resubida', label: 'Resubida' },
    { value: '#Comunicado', label: 'Comunicado' },
    { value: '#Colección', label: 'Colección' },
    { value: '#ContenidoExclusivo', label: 'Contenido exclusivo' }
];

const URL_PASOS_PARA_SER_VIP =
    'https://telegra.ph/Cu%C3%A1l-es-el-proceso-para-completar-mi-mebres%C3%ADa-VIP-11-16';

const StylesSelected = {
    width: '100%',
    marginBottom: '20px'
};

const StylesGridContainer = {
    backgroundColor: '#EFF5F5',
    marginTop: '10px',
    borderRadius: '5px',
    padding: '10px'
};

// GRUPO PRINCIPAL -1001527192006
// AnimeGalaxy.net (Canal) -1001500601482
// Pan •Noticias• -1001615539761
const arrayChatID = ['-1001527192006', '-1001500601482', '-1001615539761'];
// const chatID = '-1001637261112';

const TelegramLibre = () => {
    const { dataAEnviar, postWordpress, view, search } = useStore();

    const [tipoPost, setTipoPost] = useState(
        `${view === 4 ? '#NuevoPost' : ''}`
    );
    const [dataPost, setDataPost] = useState({});
    const [arrayDataPost, setArrayDataPost] = useState([]);
    const [textoAdicional, setTextoAdicional] = useState('');
    const [nombresCategorias, setNombresCategorias] = useState([]);
    const [alias, setAlias] = useState('');
    const [pesoArchivo, setPesoArchivo] = useState('');

    const urlPortada = extraerUrlsDeTextos(dataPost?.content?.rendered);
    const enlaceVip = extraerEnlaceVip(dataPost?.content?.rendered);
    const enlaceFree = extraerEnlaceFree(dataPost?.content?.rendered);
    const userData = getLocalStorage('user') || {};
    const nombreServidores = obtenerNombresDeHost(dataAEnviar?.misServidores);

    const [imageFiles, setImageFiles] = useState([]);
    const [imageLoading, setImageLoading] = useState(false);
    const [message, setMessage] = useState('');

    /**
     * VALIDAR EL PESO DEL ARCHIVO
     */
    const pesoArchivoString =
        search?.typeSearch === '1'
            ? ` Peso: ${dataAEnviar?.PesoArchivo}`
            : search?.typeSearch
            ? ` Peso: ${dataAEnviar?.PesoArchivo} GB Aprox./Capitulo`
            : '';

    /**
     * FUNCIONALIDAD PARA ENVIAR POST A TELEGRAM
     */

    const handlePost = async () => {
        if (imageFiles.length === 0) {
            toast.error('Debes subir al menos una imagen');
            return;
        }

        if (!message.trim()) {
            toast.error('Debes escribir un mensaje');
            return;
        }

        try {
            for (let chatId = 0; chatId < arrayChatID.length; chatId++) {
                if (imageFiles.length > 1) {
                    // Preparar el grupo de medios para múltiples imágenes
                    const media = imageFiles.map((img, index) => ({
                        type: 'photo',
                        media: img,
                        ...(index === 0 && {
                            caption: message,
                            parse_mode: 'html'
                        })
                    }));

                    console.log('Enviando grupo de medios:', media);

                    const response = await makePostRequest({
                        method: 'sendMediaGroup',
                        chat_id: arrayChatID[chatId],
                        media: media // No necesitamos JSON.stringify aquí
                    });

                    console.log('Respuesta de Telegram:', response);
                } else {
                    // Enviar una sola imagen
                    console.log('Enviando imagen única:', {
                        method: 'sendPhoto',
                        chat_id: arrayChatID[chatId],
                        photo: imageFiles[0],
                        caption: message
                    });

                    const response = await makePostRequest({
                        method: 'sendPhoto',
                        chat_id: arrayChatID[chatId],
                        photo: imageFiles[0],
                        caption: message,
                        parse_mode: 'html'
                    });

                    console.log('Respuesta de Telegram:', response);
                }
            }

            // Limpiar el estado después de enviar exitosamente
            setImageFiles([]);
            setMessage('');
            toast.success('¡Mensaje enviado exitosamente!');
        } catch (error) {
            console.error('Error al enviar a Telegram:', error);
            toast.error(
                'Error al enviar el mensaje: ' +
                    (error.message || 'Error desconocido')
            );
        }
    };

    const handleFileChange = async e => {
        e.preventDefault();
        const files = Array.from(e.target.files);

        if (files.length === 0) return;

        // Validar el tamaño y tipo de archivos
        const validFiles = files.filter(file => {
            const isValidType = file.type.startsWith('image/');
            const isValidSize = file.size <= 10 * 1024 * 1024; // 10MB max
            return isValidType && isValidSize;
        });

        if (validFiles.length !== files.length) {
            toast.error(
                'Algunos archivos no son válidos. Asegúrate de que sean imágenes y no excedan 10MB'
            );
        }

        try {
            setImageLoading(true);
            const uploadPromises = validFiles.map(async file => {
                const result = await subirPosterImgur(file);
                console.log('result', result);
                if (!result) {
                    throw new Error('No se pudo subir la imagen');
                }

                // Manejar el formato específico de la respuesta
                if (result.data?.image?.url) {
                    return result.data.image.url;
                } else if (result.data?.url) {
                    return result.data.url;
                } else if (result.data?.link) {
                    return result.data.link;
                } else if (result.data?.display_url) {
                    return result.data.display_url;
                } else if (result.url) {
                    return result.url;
                } else if (typeof result === 'string') {
                    return result;
                }
                throw new Error('Formato de respuesta no válido');
            });

            const uploadedImages = await Promise.all(uploadPromises);
            const validUrls = uploadedImages.filter(url => url); // Filtrar null o undefined

            if (validUrls.length > 0) {
                setImageFiles(prev => [...prev, ...validUrls]);
                toast.success(
                    `${validUrls.length} imagen(es) cargada(s) correctamente`
                );
            } else {
                throw new Error('No se pudo subir ninguna imagen');
            }
        } catch (error) {
            console.error('Error al subir imágenes:', error);
            toast.error(
                'Error al subir las imágenes: ' +
                    (error.message || 'Error desconocido')
            );
        } finally {
            setImageLoading(false);
        }
    };

    const removeImage = index => {
        setImageFiles(prev => prev.filter((_, i) => i !== index));
    };

    return (
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12} sx={StylesGridContainer}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    <img
                        src={images.Logo_Telegram2}
                        alt=""
                        style={{ width: '60px' }}
                    />
                    <h2 style={{ fontWeight: 'bold' }}>
                        Publicador de Post Telegram Libre
                    </h2>
                </Box>

                {/* Previsualización de imágenes */}
                <Stack
                    mb={2}
                    direction={'row'}
                    spacing={2}
                    flexWrap="wrap"
                    justifyContent={'center'}
                    sx={{ gap: '10px' }}>
                    {imageFiles.map((img, index) => (
                        <Box
                            key={index}
                            position="relative"
                            sx={{
                                width: '200px',
                                height: '200px',
                                borderRadius: '8px',
                                overflow: 'hidden'
                            }}>
                            <img
                                src={img}
                                alt={`Imagen ${index + 1}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                }}
                            />
                            <Button
                                size="small"
                                color="error"
                                onClick={() => removeImage(index)}
                                sx={{
                                    position: 'absolute',
                                    top: 5,
                                    right: 5,
                                    minWidth: '30px',
                                    width: '30px',
                                    height: '30px',
                                    borderRadius: '50%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(255, 255, 255, 1)'
                                    }
                                }}>
                                ×
                            </Button>
                        </Box>
                    ))}
                </Stack>

                <Box>
                    <Box mb={2}>
                        {imageLoading ? (
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: '100px' }}>
                                <CircularProgress />
                            </Stack>
                        ) : (
                            <DragAndDrop
                                title={'Arrastra una o varias imágenes'}
                                accept="image/*"
                                handleChange={handleFileChange}
                                multiple={true}
                            />
                        )}
                    </Box>

                    <UnstyledTextareaIntroduction
                        setMessage={setMessage}
                        message={message}
                    />

                    <Button
                        sx={{
                            backgroundColor: '#ffc107',
                            color: '#000',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: '#ffb300'
                            }
                        }}
                        onClick={handlePost}
                        variant="contained"
                        disabled={imageLoading || imageFiles.length === 0}
                        fullWidth={true}>
                        <div className="d-flex gap-2 align-items-center">
                            <img
                                src={images.Logo_Telegram3}
                                alt="telegram"
                                style={{ width: '26px' }}
                            />
                            {imageLoading
                                ? 'Cargando...'
                                : 'Enviar mensaje a Telegram'}
                        </div>
                    </Button>
                </Box>
            </Grid>
            <Toaster position="top-center" reverseOrder={false} />
        </Grid>
    );
};

export default TelegramLibre;
