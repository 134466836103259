import { Store } from 'pullstate';

const ApartadosStore = new Store({});

const setPostApartados = post => {
    ApartadosStore.update(s => post);
};

function useApartados() {
    const apartados = ApartadosStore.useState(s => s);

    return { apartados, setPostApartados };
}

export { useApartados };
