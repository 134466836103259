import { Store } from 'pullstate';

const ValuesPortadaStore = new Store({});

const setPostValuesPortada = post => {
    ValuesPortadaStore.update(s => post);
};

function useValuesPortada() {
    const valuesPortada = ValuesPortadaStore.useState(s => s);

    return { valuesPortada, setPostValuesPortada };
}

export { useValuesPortada };
