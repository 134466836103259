import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';

const OBJ_KEYS_POST = {
    status: { label: 'Status', type: 'text' },
    title: { label: 'Título', type: 'nested', key: 'rendered' },
    user: { label: 'Uploader', type: 'nested', key: 'name' }
};

const FichaCoincidence = ({ dataPost }) => {
    console.log('dataPost', dataPost);
    return (
        <Box>
            {Object.keys(dataPost).length > 0 && (
                <>
                    {Object.keys(OBJ_KEYS_POST).map(key => (
                        <Stack direction={'row'} spacing={1}>
                            <Typography
                                variant="span"
                                sx={{ fontWeight: 'bold' }}
                                color="whiteMain.main">
                                {OBJ_KEYS_POST[key].label}:
                            </Typography>
                            <Typography variant="span" color="white.light">
                                {OBJ_KEYS_POST[key].type === 'nested'
                                    ? dataPost[key][OBJ_KEYS_POST[key].key]
                                    : dataPost[key]}
                            </Typography>
                        </Stack>
                    ))}
                    <Box mt={1}>
                        <Button
                            size="small"
                            variant="contained"
                            disableElevation
                            color="yellow"
                            href={dataPost.link}
                            target="_blank"
                            sx={{
                                fontWeight: 'bold',
                                color: '#fff',
                                fontSize: '14px'
                            }}>
                            Ver Post
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default FichaCoincidence;
