import React, { useEffect, useState } from 'react';
import {
    Form,
    InputGroup,
    Row,
    Col,
    Button,
    Tab,
    Tabs,
    Spinner
} from 'react-bootstrap';
import { FaPlus, FaRegSave } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { getpostWordpress, postUpdateWordpress } from '../../utils/apiwp';
import {
    capturarNumeroCapitulo,
    crearArrayDeTags,
    getCategoriesWordpress,
    insertarCodigoHTML,
    obtenerParametrosURLsClasificados,
    obtenerPasteID
} from '../../utils/functions';
import { Formik, Form as MyForm } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import './mypost.css';
import Telegram from '../../components/Telegram/Telegram';
import CrearPortada from '../../components/CrearPortada';
import EditarFechaPost from './components/EditarFechaPost';
import metadata from '../../metadata.json';
import { useDispatch } from '../../store/StoreProvider';
import { getPlantillas } from '../../utils/api';
import { types } from '../../store/storeReducer';
import EditPostView from './components/EditPostView';
import EditarPaste from './components/EditarPaste';
import EditPaste from '../../components/paste/EditPaste';
import { getPastes } from '../../utils/apipaste';

const MyPost = () => {
    const dispatch = useDispatch();
    const [postData, setPostData] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagsCalidad, setTagsCalidad] = useState([]);
    const [tagsCasas, setTagsCasas] = useState([]);
    const [tagsResolucion, setTagsResolucion] = useState([]);
    const [tagsYear, setTagsYear] = useState([]);
    const [tagsOtras, setTagsOtras] = useState([]);
    const [pastes, setPastes] = useState([]);
    const [content, setContent] = useState('');

    const params = useParams();
    const postId = params?.id;

    const getPost = async postId => {
        setLoading(true);
        const data = await getpostWordpress(postId);
        const dataa = {
            ...data,
            ping_status: data?.ping_status ? data?.ping_status : 'closed',
            categories: data?.categories?.map(cat => cat?.toString()),
            tags: data?.tags?.map(tag => tag?.toString())
        };
        setPostData(dataa);
        await handleGetPastes(dataa);
        setLoading(false);

        dispatch({
            type: types.POST_WORDPRESS,
            payload: data
        });
    };

    const getCategoriasYEtiquetas = async () => {
        const cat = await getCategoriesWordpress('categories');
        const tag = await getCategoriesWordpress('tags');
        const { data } = await getPlantillas();
        const dataFormat = data?.map(i => ({
            label: i.name,
            value: i.name,
            url: i.url,
            id: i.id
        }));

        const metadataExtra = [
            ...metadata?.map(obj =>
                obj.name === 'Extra' ? { name: 'Extra', data: dataFormat } : obj
            )
        ];

        const catN = cat.map(c => ({ ...c, id: c.id?.toString() }));
        const tagN = tag.map(t => ({ ...t, id: t.id?.toString() }));

        const calidadN = crearArrayDeTags(tag, metadata, 'Calidad');
        const misCasas = crearArrayDeTags(tag, metadataExtra, 'Extra');
        const resolucionN = [
            ...crearArrayDeTags(tag, metadata, 'Resolucion'),
            ...crearArrayDeTags(tag, metadata, 'Extra')
        ];
        const yearN = tag?.filter(item => Number(item.name));
        const arrayAll = [...calidadN, ...misCasas, ...resolucionN, ...yearN];
        let variations = tag.filter(
            res =>
                !arrayAll.some(id_prueba => id_prueba.name.includes(res.name))
        );

        setCategorias(catN);
        setTags(tagN);
        setTagsCalidad(calidadN);
        setTagsCasas(misCasas);
        setTagsResolucion(resolucionN);
        setTagsYear(yearN);
        setTagsOtras(variations);
    };

    const handleUpdatePost = async (postId, values) => {
        setLoadingUpdate(true);

        const data = await postUpdateWordpress(postId, values);
        setPostData(data.data);
        setLoadingUpdate(false);
        return data;
    };
    const handleGetPastes = async postData => {
        const { data } = await getPastes();
        const objParamUrls = obtenerParametrosURLsClasificados(
            postData?.content?.rendered
        );
        const paste = obtenerPasteID(objParamUrls, data);
        setPastes(paste);
    };

    useEffect(() => {
        getPost(postId);
        getCategoriasYEtiquetas();
    }, []);

    const status =
        postData?.status === 'publish'
            ? 'Publicado'
            : postData?.status === 'pending'
            ? 'Pendiente'
            : postData?.status === 'draft'
            ? 'Borrador'
            : 'Eliminado';

    const handleNumberCapitulos = (e, setValues, values) => {
        const value = e.target.value;

        if (value === '') {
            setValues({ ...values, capitulosDisponibles: '' });
            return;
        }
        if (value < 0) {
            setValues({ ...values, capitulosDisponibles: 0 });
            return;
        }
        if (value >= 0) {
            values.content = insertarCodigoHTML(values.content, value);
            setValues({ ...values, capitulosDisponibles: value });
            return;
        }
    };

    const handleSubmit = async values => {
        const newData = {
            ...postData,
            ...values,
            content,
            categories: values.miscategoriasfull,
            tags: values.mistags
        };
        const dataUp = await handleUpdatePost(postData?.id, newData);

        if (dataUp.status !== 200) {
            toast.error(`Error: ${dataUp.response.data.message}`, {
                duration: 12000
            });
            return;
        }
        dispatch({
            type: types.POST_WORDPRESS,
            payload: dataUp?.data
        });
        toast.success('Post Actualizado!');
    };

    const deleteIframe = htmlContent => {
        // Función para convertir la URL embed a la URL completa de YouTube
        function convertEmbedToFullUrl(embedUrl) {
            // Usa una expresión regular para extraer el ID del video
            const videoIdMatch = embedUrl.match(/\/embed\/([^?]+)/);
            if (!videoIdMatch) {
                throw new Error(
                    'No se pudo encontrar el ID del video en la URL embed'
                );
            }

            // El ID del video está en el segundo grupo de captura
            const videoId = videoIdMatch[1];

            // Construye la URL completa de YouTube
            const fullUrl = `https://www.youtube.com/watch?v=${videoId}`;

            return fullUrl;
        }

        // Expresión regular para encontrar el iframe y extraer su URL src
        const iframeRegex = /<iframe[^>]+src="([^"]+)"[^>]*><\/iframe>/;

        // Busca el iframe en el contenido HTML
        const iframeMatch = htmlContent?.match(iframeRegex);
        if (iframeMatch) {
            // La URL del iframe está en el segundo grupo de captura
            const embedUrl = iframeMatch[1];

            // Convierte la URL embed a la URL completa
            const fullUrl = convertEmbedToFullUrl(embedUrl);

            // Reemplaza el iframe con la URL completa
            const updatedHtmlContent = htmlContent?.replace(
                iframeRegex,
                fullUrl
            );
            return updatedHtmlContent;
        } else {
            return htmlContent;
        }
    };
    return (
        <>
            {loading ? (
                <div className="contentLoading">
                    <span className="loader"></span>
                </div>
            ) : (
                <Row>
                    <Row>
                        <Col className="mb-3">
                            <h1>EDITAR POST - ID {postData?.id}</h1>
                        </Col>
                    </Row>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            mistags: postData?.tags || [],
                            miscategoriasfull: postData?.categories || [],
                            title: postData?.title?.rendered,
                            status: postData?.status,
                            content: deleteIframe(postData?.content?.rendered),
                            capitulosDisponibles: capturarNumeroCapitulo(
                                postData?.content?.rendered
                            )
                        }}
                        onSubmit={handleSubmit}>
                        {({ values, handleChange, handleBlur, setValues }) => (
                            <MyForm id="formi-mypost">
                                <Row>
                                    <Col md={7}>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlInput1">
                                            <Form.Control
                                                type="text"
                                                placeholder="Titulo"
                                                // defaultValue={postData?.title?.rendered}
                                                name="title"
                                                value={values?.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={2}>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlInput2">
                                            <Form.Control
                                                type="number"
                                                placeholder="Cap. disponibles"
                                                // defaultValue={postData?.title?.rendered}
                                                name="capitulosDisponibles"
                                                value={
                                                    values?.capitulosDisponibles
                                                }
                                                onChange={e =>
                                                    handleNumberCapitulos(
                                                        e,
                                                        setValues,
                                                        values
                                                    )
                                                }
                                                // onBlur={handleBlur}
                                            />
                                        </Form.Group>
                                    </Col> */}
                                    <Col md={3}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">
                                                Status
                                            </InputGroup.Text>
                                            <Form.Select
                                                aria-label="Default select example"
                                                name="status"
                                                value={values?.status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}>
                                                <option>
                                                    Actual: {status}
                                                </option>
                                                <option value="publish">
                                                    Publicado
                                                </option>
                                                <option value="draft">
                                                    Draft
                                                </option>
                                                <option value="pending">
                                                    En Moderación
                                                </option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col className="d-grid gap-2">
                                        <Button
                                            form="formi-mypost"
                                            variant="info"
                                            type="submit"
                                            className="mb-3 btn-warning">
                                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                                {!loadingUpdate && (
                                                    <FaRegSave />
                                                )}
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    hidden={!loadingUpdate}
                                                    className="mx-2"
                                                />
                                                Actualizar
                                            </div>
                                        </Button>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Tabs
                                            defaultActiveKey="editar-contenido"
                                            id="uncontrolled-tab-example"
                                            className="mb-3">
                                            <Tab
                                                eventKey="editar-contenido"
                                                title="Editar">
                                                <EditPostView
                                                    content={
                                                        postData?.content
                                                            ?.rendered
                                                    }
                                                    setPostData={setPostData}
                                                    setContent={setContent}
                                                />
                                            </Tab>
                                            <Tab
                                                eventKey="contenido"
                                                title="Contenido">
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control
                                                        name="content"
                                                        onChange={handleChange}
                                                        // onBlur={handleBlur}
                                                        style={{
                                                            fontFamily:
                                                                'Consolas,Monaco,monospace',
                                                            fontSize: '13px'
                                                        }}
                                                        as="textarea"
                                                        rows={30}
                                                        // defaultValue={
                                                        //     postData?.content
                                                        //         ?.rendered
                                                        // }
                                                        value={values?.content}
                                                    />
                                                </Form.Group>
                                            </Tab>
                                            <Tab
                                                eventKey="crear-portada"
                                                title="Crear Portada">
                                                <CrearPortada
                                                    content={
                                                        postData?.content
                                                            ?.rendered
                                                    }
                                                    setPostData={setPostData}
                                                    setContent={setContent}
                                                />
                                            </Tab>

                                            <Tab
                                                eventKey="categoriasyetiquetas"
                                                title="Categorias & Etiquetas">
                                                <Row
                                                    style={{
                                                        backgroundColor:
                                                            '#e9ecef'
                                                    }}>
                                                    <Col
                                                        sm={12}
                                                        className="text-center mb-2">
                                                        <h6>Categorias</h6>
                                                    </Col>
                                                    {categorias?.map(
                                                        categoria => (
                                                            <Col
                                                                sm={6}
                                                                md={4}
                                                                lg={3}
                                                                key={
                                                                    categoria.id
                                                                }>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={`postcategoria${categoria.id}`}
                                                                    label={categoria.name.toUpperCase()}
                                                                    size="sm"
                                                                    name="miscategoriasfull"
                                                                    value={
                                                                        categoria.id
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    checked={values.miscategoriasfull?.includes(
                                                                        categoria.id
                                                                    )}
                                                                />
                                                            </Col>
                                                        )
                                                    )}
                                                </Row>
                                                {/* <Row
                                                    className="text-center"
                                                    style={{
                                                        borderTop:
                                                            'solid 10px #fff',
                                                        backgroundColor:
                                                            '#e9ecef'
                                                    }}>
                                                    <Col
                                                        sm={12}
                                                        className="mt-3">
                                                        <h6>Etiquetas</h6>
                                                    </Col>

                                                    {tags?.map(tag => (
                                                        <Col
                                                            sm={6}
                                                            md={4}
                                                            lg={3}
                                                            key={tag.id}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`posttag${tag.id}`}
                                                                label={tag.name.toUpperCase()}
                                                                size="sm"
                                                                name="mistags"
                                                                value={tag.id}
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                checked={values?.mistags?.includes(
                                                                    tag?.id
                                                                )}
                                                            />
                                                        </Col>
                                                    ))}
                                                </Row> */}
                                                <Row
                                                    className="text-center"
                                                    style={{
                                                        borderTop:
                                                            'solid 10px #fff',
                                                        backgroundColor:
                                                            '#e9ecef'
                                                    }}>
                                                    <Col
                                                        sm={12}
                                                        className="mt-3">
                                                        <h6>Etiquetas</h6>
                                                    </Col>
                                                </Row>
                                                <Row
                                                    style={{
                                                        borderTop:
                                                            'thick green',
                                                        backgroundColor:
                                                            '#e9ecef'
                                                    }}
                                                    className="pt-3 pb-3">
                                                    <Col sm={12}>
                                                        <h6>Year</h6>
                                                    </Col>
                                                    {tagsYear?.map(tag => (
                                                        <Col
                                                            sm={6}
                                                            md={4}
                                                            lg={3}
                                                            key={tag.id}>
                                                            {Number(
                                                                tag.name
                                                            ) ? (
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={`step4tag${tag.id}`}
                                                                    label={tag.name.toUpperCase()}
                                                                    size="sm"
                                                                    name="mistags"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    value={
                                                                        tag.id
                                                                    }
                                                                    checked={values.mistags?.includes(
                                                                        `${tag.id}`
                                                                    )}
                                                                />
                                                            ) : null}
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <Row
                                                    style={{
                                                        borderTop:
                                                            'solid 10px #fff',
                                                        backgroundColor:
                                                            '#e9ecef'
                                                    }}
                                                    className="pt-3 pb-3">
                                                    <Col sm={12}>
                                                        <h6>Calidad</h6>
                                                    </Col>
                                                    {tagsCalidad?.map(tag => (
                                                        <Col
                                                            sm={6}
                                                            md={4}
                                                            lg={3}
                                                            key={tag.id}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`step4tag${tag.id}`}
                                                                label={tag.name.toUpperCase()}
                                                                size="sm"
                                                                name="mistags"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={tag.id}
                                                                checked={values.mistags?.includes(
                                                                    `${tag.id}`
                                                                )}
                                                            />
                                                        </Col>
                                                    ))}
                                                </Row>

                                                <Row
                                                    style={{
                                                        borderTop:
                                                            'solid 10px #fff',
                                                        backgroundColor:
                                                            '#e9ecef'
                                                    }}
                                                    className="pt-3 pb-3">
                                                    <Col sm={12}>
                                                        <h6>Resolución</h6>
                                                    </Col>
                                                    {tagsResolucion?.map(
                                                        tag => (
                                                            <Col
                                                                sm={6}
                                                                md={4}
                                                                lg={3}
                                                                key={tag.id}>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={`step4tag${tag.id}`}
                                                                    label={tag.name.toUpperCase()}
                                                                    size="sm"
                                                                    name="mistags"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    value={
                                                                        tag.id
                                                                    }
                                                                    checked={values.mistags?.includes(
                                                                        `${tag.id}`
                                                                    )}
                                                                />
                                                            </Col>
                                                        )
                                                    )}
                                                </Row>
                                                <Row
                                                    style={{
                                                        borderTop:
                                                            'solid 10px #fff',
                                                        backgroundColor:
                                                            '#e9ecef'
                                                    }}
                                                    className="pt-3 pb-3">
                                                    <Col sm={12}>
                                                        <h6>Casas</h6>
                                                    </Col>
                                                    {tagsCasas?.map(tag => (
                                                        <Col
                                                            sm={6}
                                                            md={4}
                                                            lg={3}
                                                            key={tag.id}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`step4tag${tag.id}`}
                                                                label={tag.name.toUpperCase()}
                                                                size="sm"
                                                                name="mistags"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={tag.id}
                                                                checked={values.mistags?.includes(
                                                                    `${tag.id}`
                                                                )}
                                                            />
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <Row
                                                    style={{
                                                        borderTop:
                                                            'solid 10px #fff',
                                                        backgroundColor:
                                                            '#e9ecef'
                                                    }}
                                                    className="pt-3 pb-3">
                                                    <Col sm={12}>
                                                        <h6>Otras</h6>
                                                    </Col>
                                                    {tagsOtras?.map(tag => (
                                                        <Col
                                                            sm={6}
                                                            md={4}
                                                            lg={3}
                                                            key={tag.id}>
                                                            {!Number(
                                                                tag.name
                                                            ) ? (
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={`step4tag${tag.id}`}
                                                                    label={tag.name.toUpperCase()}
                                                                    size="sm"
                                                                    name="mistags"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    value={
                                                                        tag.id
                                                                    }
                                                                    checked={values.mistags?.includes(
                                                                        `${tag.id}`
                                                                    )}
                                                                />
                                                            ) : null}
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Tab>
                                            <Tab
                                                eventKey="editar-fecha"
                                                title="Editar Fecha">
                                                <EditarFechaPost
                                                    postData={postData}
                                                />
                                            </Tab>
                                            <Tab
                                                eventKey="telegram"
                                                title="Telegram">
                                                <Telegram />
                                            </Tab>
                                            {pastes?.map(post => (
                                                <Tab
                                                    key={post.pasteid}
                                                    eventKey={`paste-${post.type}`}
                                                    title={`Paste ${post.type.toUpperCase()}`}>
                                                    <EditPaste
                                                        pasteId={post.pasteid}
                                                    />
                                                </Tab>
                                            ))}
                                            {/* <EditarPaste
                                                content={
                                                    postData?.content?.rendered
                                                }
                                                // TabPastes={<TabPastes />}
                                            /> */}
                                            {/* <Tab
                                                eventKey="paste-vip"
                                                title="Paste VIP">
                                                <EditarPaste
                                                    content={
                                                        postData?.content
                                                            ?.rendered
                                                    }
                                                    typePaste={'vip'}
                                                />
                                            </Tab>
                                            <Tab
                                                eventKey="paste-free"
                                                title="Paste FREE">
                                                <EditarPaste
                                                    content={
                                                        postData?.content
                                                            ?.rendered
                                                    }
                                                    typePaste={'free'}
                                                />
                                            </Tab> */}
                                        </Tabs>
                                    </Col>
                                </Row>
                            </MyForm>
                        )}
                    </Formik>
                    <Toaster position="bottom-center" reverseOrder={false} />
                </Row>
            )}
        </>
    );
};
export default MyPost;

const TabPastes = ({ postId, type }) => {
    return (
        <>
            {type === 'vip' ? (
                <Tab eventKey="paste-vip" title="Paste VIP">
                    <EditPaste pasteId={postId} />
                </Tab>
            ) : (
                <Tab eventKey="paste-free" title="Paste FREE">
                    <EditPaste pasteId={postId} />
                </Tab>
            )}
        </>
    );
};
