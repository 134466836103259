import React, { useState, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { deletePostApartado } from '../service/services';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { useSearchFicharPost } from '../store/store';
import { getLocalStorage } from '../../../utils/functions';
import useFichar from '../hooks/useFichar';
import { HORA_FINALIZAR } from '../../../constants';

const ShowButtons = ({ row }) => {
    const userData = getLocalStorage('user');
    const { handleSubmitTmdb } = useFichar(row);
    const { posts, setPosts } = useSearchFicharPost();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleShow = () => setShow(!show);

    const handleDeletePost = async (PostId, handleShow) => {
        setLoading(true);
        await deletePostApartado(PostId);

        setPosts(posts.filter(item => item.id !== PostId));
        setLoading(false);
        handleShow();
    };

    // Calculamos si los botones deben mostrarse basado en el tiempo transcurrido
    const shouldShowButtons = useMemo(() => {
        if (!row?.apar_expiration_date) return true;

        const expirationDate = new Date(row.apar_expiration_date);
        const now = new Date();
        const hoursElapsed = (now - expirationDate) / (1000 * 60 * 60);

        // Mostrar botones solo después de 24 horas de la fecha de expiración
        return hoursElapsed >= 24;
    }, [row?.apar_expiration_date]);

    // Calculamos si debemos mostrar el botón de editar
    const shouldShowEditButton = useMemo(() => {
        if (!row?.apar_expiration_date) return true;

        const expirationDate = new Date(row.apar_expiration_date);
        const now = new Date();
        const hoursElapsed = (now - expirationDate) / (1000 * 60 * 60);

        // Mostrar botón de editar solo antes de las 2 horas
        return hoursElapsed < HORA_FINALIZAR;
    }, [row?.apar_expiration_date]);

    const showButtonDeleteAdmin = userData.roles === 'admin';

    return (
        <>
            {row?.apar_uploader === userData.alias ? (
                <div className="d-flex gap-2">
                    {userData.alias === row.apar_uploader &&
                    !row.apar_completed &&
                    shouldShowEditButton ? (
                        <LoadingButton
                            {...sxButtonEdit}
                            onClick={handleSubmitTmdb}>
                            <EditIcon />
                        </LoadingButton>
                    ) : null}

                    {((shouldShowButtons || row.apar_completed) && row?.apar_uploader === userData.alias || 
                        userData.roles === 'admin') && (
                        <button
                            title={`Eliminar`}
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleShow()}>
                            <DeleteForeverIcon />
                        </button>
                    )}
                </div>
            ) : (
                <div className="d-flex gap-2">
                    {userData.roles === 'admin' && (
                        <button
                            title={`Eliminar`}
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleShow()}>
                            <DeleteForeverIcon />
                        </button>
                    )}
                </div>
            )}

            <Modal
                show={show}
                onHide={handleShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ zIndex: 2000 }}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Esta seguro de eliminar {row.apar_title}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleShow}>
                        Cancelar
                    </Button>
                    <LoadingButton
                        {...sxButton}
                        loading={loading}
                        onClick={() => handleDeletePost(row.id, handleShow)}>
                        Eliminar
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ShowButtons;

const sxButton = {
    sx: {
        backgroundColor: '#FFC206',
        color: 'black',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#d1a10a',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained',
    disableElevation: true
};
const sxButtonEdit = {
    sx: {
        backgroundColor: '#211951',
        color: '#F0F3FF',
        '&:hover': {
            backgroundColor: '#836FFF',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained',
    disableElevation: true
};
