import { Store } from 'pullstate';

const SearchCoincidenceStore = new Store({});

const setPostSearchCoincidence = post => {
    SearchCoincidenceStore.update(s => post);
};

function useSearchCoincidence() {
    const searchCoincidence = SearchCoincidenceStore.useState(s => s);

    return { searchCoincidence, setPostSearchCoincidence };
}

export { useSearchCoincidence };
