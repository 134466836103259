import React, { useEffect } from 'react';
import { useDispatch } from '../../../store/StoreProvider';
import {
    crearArrayValuesColeccion,
    crearArrayValuesSeasons,
    definirTrailer,
    handleDirectors,
    handleRuntime
} from '../../../utils/functions';
import { getMovies } from '../../../utils/themvoies';
import { useSearchFicharPost } from '../store/store';
import { types } from '../../../store/storeReducer';
import { getMovieIdJust } from '../../../utils/justWhatch';
import { useSearch } from '../../movie/store';

const typesSearch = {
    tv: '0',
    movie: '1',
    collection: '2'
};

const useFichar = row => {
    const { apar_tmdb: tmdb, apar_type: type, apar_title: title } = row;
    const dispatch = useDispatch();
    const {
        datosbusqueda,
        openModal,
        setDatosBusqueda,
        setOpenModal,
        setShowButtonFilmaffinity,
        setPost
    } = useSearchFicharPost();
    const [search, setSearch, setLoadingSearch] = useSearch();

    const { idioma } = datosbusqueda;
    const typeSearch = typesSearch[type];

    const handleSubmitTmdb = async e => {
        setSearch(title);
        setPost(row);
        setShowButtonFilmaffinity(true);
        dispatch({ type: types.LOADING, payload: true });
        const datam = await getMovies({
            ...datosbusqueda,
            tmdb,
            typeSearch
        });
        if (typeSearch === '0') {
            datam.seasons = crearArrayValuesSeasons(datam);
        }

        let datamMovie = null;
        if (typeSearch === '2') {
            datamMovie = await getMovies({
                tmdb: datam?.parts?.[0]?.id,
                movie: null,
                typeSearch: '1',
                idioma: idioma
            });
            datam.production_countries = datamMovie.production_countries;
            datam.credits = datamMovie.credits;
            datam.runtime = datamMovie.runtime;
            datam.parts = crearArrayValuesColeccion(datam);
        }

        const isMovie = typeSearch === '0' ? 'tv' : 'movie';
        let dataJust = null;
        if (typeSearch === '2') {
            dataJust = await getMovieIdJust(datam?.parts?.[0]?.id, isMovie);
        } else {
            dataJust = await getMovieIdJust(tmdb, isMovie);
        }

        dispatch({ type: types.PELIS_JUSTWHATCH_ID, payload: dataJust });

        const misDirectores = handleDirectors(datam, typeSearch === '1');
        const trailer1 = definirTrailer(datam) ?? 'Sin Trailer';
        const runtime = handleRuntime(datam);
        const year =
            datam?.release_date?.slice(0, -6) ||
            datam?.first_air_date?.slice(0, -6) ||
            'Sin año';
        const paisOrigen = datam?.production_countries?.[0]?.name || 'Sin pais';
        const generos =
            datam?.genres?.length > 0
                ? datam.genres.flatMap(ge =>
                      ge.name.split(' & ').map(g => ' ' + g)
                  )
                : null;
        const director = misDirectores.join(',') || 'Sin Director';
        const imdb = datam?.external_ids?.imdb_id || 'Sin IMDB';
        const trailer = trailer1?.[0]?.url || 'Sin trailer';
        const description = datam?.overview || 'Sin descripcion';
        const original_title = datam?.original_title || datam?.name;

        const dataMovie = {
            ...datam,
            original_title,
            poster_path: `https://www.themoviedb.org/t/p/original${datam?.poster_path}`,
            poster_path_respaldo: `https://www.themoviedb.org/t/p/original${datam?.poster_path}`,
            misDirectores,
            trailer,
            runtime,
            year,
            paisOrigen,
            generos,
            director,
            imdb,
            description
        };
        dispatch({
            type: types.MOVIE,
            payload: {
                ...dataMovie,
                typeSearch,
                idioma,
                myidioma: idioma
            }
        });
        dispatch({
            type: types.SEARCH,
            payload: {
                ...datosbusqueda,
                typeSearch,
                idioma,
                myidioma: idioma
            }
        });
        dispatch({
            type: types.STATE_CALIDADES_SELECTED,
            payload: {
                Calidad: row.apar_calidad,
                Resolucion: row.apar_resolucion,
                Idioma: row.apar_idioma
            }
        });
        dispatch({ type: types.LOADING, payload: false });
        setOpenModal(!openModal);
        // !isPoster && dispatch({ type: types.VIEW, payload: 1 });
    };

    useEffect(() => {
        if (tmdb) {
            setDatosBusqueda({ ...datosbusqueda, tmdb });
        }
    }, [tmdb]);

    return { handleSubmitTmdb };
};

export default useFichar;
